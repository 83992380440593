// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { HomeGlyph24Icon } from 'assets/icons'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import { useMe, useOrganizationWithOptions } from 'src/core/graphql/hooks'
import ListMenuButton from '~/components/ListMenuButton'

const determineSelectedMenuLink = (links, currentLocationPathname) => {
   let indexOfLinkWithLongestMatch = -1

   links.forEach((link, i) => {
      if (currentLocationPathname.startsWith(link.url)) {
         if (indexOfLinkWithLongestMatch > -1) {
            if (link.url.length > links[indexOfLinkWithLongestMatch].url.length) {
               indexOfLinkWithLongestMatch = i
            }
         } else {
            indexOfLinkWithLongestMatch = i
         }
      }
   })

   return indexOfLinkWithLongestMatch
}

const iconSize = '12px'

const Nav = ({
   display,
   flex,
   orientation
}) => {
   const location = useLocation()
   const { me } = useMe()
   const isGatherDeveloper = me?.email.endsWith('@mother.co')
   const { organization, loading } = useOrganizationWithOptions({
      withGlobalNavLinksTargetMetadata: true
   })

   if (loading) {
      return null
   }

   // TODO: This should probably become a field stored in organization #975
   const hasNavIcons = organization?.slug === 'banff-pipeline-workshop'
   const defaultNavIconColor = organization?.theme.highlightColor || '#000'
   const showLatestButton = isGatherDeveloper && organization.slug !== 'banff-pipeline-workshop'

   const horizontalButtonProps = {
      style: {
         color: 'var(--mainMenuButtonTextColor)',
         padding: '5px',
         margin: '0px 8px'
      }
   }

   const mainMenuLinks = organization.globalNavLinks
      .map(link => ({
         key: link.id,
         active: false,
         icon: link.initiative?.logoIconName
            ? (
               <View
                  flex="0 0 auto"
                  width="26px"
                  height="26px"
                  borderRadius="50%"
                  style={{ overflow: 'hidden' }}>
                  <InitiativeDefaultThumbnail
                     highlightColor={link.initiative.logoIconColor || defaultNavIconColor}
                     iconName={link.initiative.logoIconName}
                     iconSize={iconSize}
                  />
               </View>
            )
            : null,
         isExternal: link.__typename === 'ExternalLinkNavElement',
         name: link.name,
         url: link.url
      }))

   const selectedLinkIndex = determineSelectedMenuLink(mainMenuLinks, location.pathname)
   if (selectedLinkIndex > -1) {
      mainMenuLinks[selectedLinkIndex].active = true
   }

   if (orientation === 'horizontal') {
      return (
         <View
            flex={flex}
            display={display}
            flexFlow="row"
            alignItems="flex-start">
            {mainMenuLinks.map((link) => {
               if (link.isExternal) {
                  return (
                     <a
                        key={link.key}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        {...horizontalButtonProps}>
                        <div>
                           {link.name}
                        </div>
                     </a>
                  )
               }

               return (
                  <NavLink
                     key={link.key}
                     isActive={() => link.active}
                     to={link.url}
                     {...horizontalButtonProps}>
                     <div>
                        {link.name}
                     </div>
                  </NavLink>
               )
            })}
            {organization.memberListEnabled && (
               <NavLink to="/-/people" {...horizontalButtonProps}>
                  <div>
                     People
                  </div>
               </NavLink>
            )}
         </View>
      )
   }

   // If Vertical orientation
   const verticalButtonProps = {
      activeBackgroundColor: 'var(--listMenuButtonActiveBackgroundColor)',
      activeTextColor: 'var(--listMenuButtonActiveTextColor)',
      paddingHorizontal: '10px',
      showPlaceholderIcon: isGatherDeveloper && organization.slug !== 'banff-pipeline-workshop'
   }

   return (
      <View
         flex={flex}
         display={display}
         flexFlow="column"
         alignItems="flex-start">
         <ListMenuButton
            icon={hasNavIcons && (
               <View
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flex="0 0 auto"
                  width="26px"
                  height="26px"
                  borderRadius="50%"
                  backgroundColor={defaultNavIconColor}
                  style={{ overflow: 'hidden' }}>
                  <HomeGlyph24Icon
                     color="#fff"
                     width={iconSize}
                     height={iconSize}
                  />
               </View>
            )}
            linkExact
            linkTo="/"
            name="Home"
            {...verticalButtonProps}
         />
         {showLatestButton && (
            <ListMenuButton
               linkTo="/-/latest"
               name="Latest"
               {...verticalButtonProps}
            />
         )}
         {organization.memberListEnabled && (
            <ListMenuButton
               icon={hasNavIcons && (
                  <View
                     flex="0 0 auto"
                     width="26px"
                     height="26px"
                     borderRadius="50%"
                     style={{ overflow: 'hidden' }}>
                     <InitiativeDefaultThumbnail
                        highlightColor={defaultNavIconColor}
                        iconName="group"
                        iconSize={iconSize}
                     />
                  </View>
               )}
               linkTo="/-/people"
               name="People"
               {...verticalButtonProps}
            />
         )}
         {mainMenuLinks.map(link => (
            <ListMenuButton
               key={link.key}
               active={link.active}
               icon={link.icon}
               linkIsExternal={link.isExternal}
               linkTo={link.url}
               name={link.name}
               showBadge={link.showBadge}
               {...verticalButtonProps}
            />
         ))}
         {/* {organization.currentUserIsAdmin && (
            <ListMenuButton
               linkTo="/-/pages/create"
               name="New Page"
               {...verticalButtonProps}
               icon={(
                  <View width="16px" paddingTop="1px" opacity="0.5">
                     <AddIcon
                        width="12px"
                        height="12px"
                        strokeWidth="2px"
                     />
                  </View>
               )}
            />
         )} */}
      </View>
   )
}

Nav.propTypes = {
   display: PropTypes.string,
   flex: PropTypes.string,
   orientation: PropTypes.oneOf(['horizontal', 'vertical'])
}

Nav.defaultProps = {
   display: 'flex',
   flex: '0 0 auto',
   orientation: 'horizontal'
}

export default Nav
