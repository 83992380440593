// =========================================================================================@@
// Last Updated Date: Feb 24, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useEffect, useContext, useState } from 'react'
import { NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import ObjectViewBody from 'src/sites/kits/Object/components/ViewBody'
import { Title } from 'src/sites/kits/UI'

// We store the current user's preferred message display mode in local storage
const lsMessageDisplayModeKey = 'message-display-mode'

const DynamicViewPageHome = ({ paddingLeft, paddingRight }) => {
   const { showNotification } = useContext(NotificationManagerContext)
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const { updateInitiative } = useUpdateInitiative()
   const isDraft = initiative.privacy === 'draft'
   const isPostOrDiscussion = ['discussion', 'post'].includes(initiative.class)
   const defaultMessageDisplayMode = isPostOrDiscussion ? 'conversation' : 'feed'
   const allowAnonSubmit = organization.slug === 'banff-pipeline-workshop'

   const [messageDisplayMode, setMessageDisplayMode] = useState(() => {
      const initialMessageDisplayMode = window.localStorage.getItem(lsMessageDisplayModeKey) ||
         defaultMessageDisplayMode

      return initialMessageDisplayMode
   })

   useEffect(() => {
      window.localStorage.setItem(lsMessageDisplayModeKey, messageDisplayMode)
   }, [messageDisplayMode])

   const handleUpdateInitiative = async (value) => {
      try {
         await updateInitiative({ id: initiative.id }, value)

         showNotification({
            message: `${initiative.type.nameSingular} updated successfully`,
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error!',
            type: 'error'
         })
      }
   }

   return (
      <View
         paddingLeft={paddingLeft}
         paddingRight={paddingRight}>
         <ObjectViewBody />
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderTop="1px solid var(--primaryLineColor)"
            paddingTop="20px"
            marginTop="40px"
            marginBottom="20px">
            <Title size="sm">
               Conversation
            </Title>
            <View
               display="flex"
               height="30px"
               borderRadius="6px"
               padding="1px"
               backgroundColor="var(--inputBackgroundColor)"
               style={{ overflow: 'hidden' }}>
               <View
                  onClick={() => setMessageDisplayMode('conversation')}
                  display="flex"
                  alignItems="center"
                  height="100%"
                  backgroundColor={messageDisplayMode === 'conversation' && '#fff'}
                  boxShadow={messageDisplayMode === 'conversation' && '0px 0px 16px rgba(0,0,0,0.1)'}
                  padding="0px 10px"
                  borderRadius="5px"
                  marginRight="4px"
                  style={{
                     fontSize: 'var(--metaTextSize)',
                     cursor: 'pointer',
                     color: messageDisplayMode === 'conversation' ? 'var(--bodyTextColor)' : 'var(--metaTextColor)',
                     opacity: messageDisplayMode === 'conversation' ? 1 : 0.5
                  }}>
                  Oldest
               </View>
               <View
                  onClick={() => setMessageDisplayMode('feed')}
                  display="flex"
                  alignItems="center"
                  height="100%"
                  backgroundColor={messageDisplayMode === 'feed' && '#fff'}
                  boxShadow={messageDisplayMode === 'feed' && '0px 0px 16px rgba(0,0,0,0.1)'}
                  padding="0px 10px"
                  borderRadius="5px"
                  style={{
                     fontSize: 'var(--metaTextSize)',
                     cursor: 'pointer',
                     color: messageDisplayMode === 'feed' ? 'var(--bodyTextColor)' : 'var(--metaTextColor)',
                     opacity: messageDisplayMode === 'feed' ? 1 : 0.5
                  }}>
                  Newest
               </View>
            </View>
         </View>
         {isDraft && (
            <View
               backgroundColor="#f5f5f5"
               borderRadius="6px"
               padding="25px"
               textAlign="center">
               <Text size="1.5[a-b] 2[c-f]" color="#888">
                  {`Comments are not available while this ${initiative.type.nameSingular} is in draft mode.`}
               </Text>
            </View>
         )}
         {!isDraft && (
            <MessageFeed
               allowAnonSubmit={allowAnonSubmit}
               allowFileAttachments={!['event', 'session'].includes(initiative.class)}
               allowMarkClosingMessage={!initiative.discussionStatus?.closed &&
                  initiative.discussionFormat === 'qa'}
               allowMessageCreate={!initiative.discussionStatus?.closed}
               closingMessageId={initiative.discussionStatus?.closingMessageId}
               currentUserCanCreate={initiative.appsMessagesCurrentUserCanCreate}
               currentUserCanModerate={initiative.currentUserCanModerateMessages}
               displayMode={messageDisplayMode}
               hasUnreadEntries={initiative.thread.hasUnreadEntries}
               messageLastViewed={initiative.thread.messageLastViewed}
               messageInputToolbarConfig={['event', 'session'].includes(initiative.class)
                  ? 'textFull'
                  : 'advanced'
               }
               onCloseMessageButtonClick={(closingMessageId) => {
                  handleUpdateInitiative({
                     discussionStatus: {
                        closed: true,
                        closingMessageId
                     }
                  })
               }}
            />
         )}
         {initiative.discussionStatus?.closed && (
            <View
               display="flex"
               justifyContent="center"
               float="left"
               width="100%"
               backgroundColor="#f5f5f5"
               borderRadius="6px"
               marginTop="40px"
               padding="25px">
               <Text size="1.5" color="#555">
                  <Link to={`/-/profile/${initiative.discussionStatus?.closedBy.id}`}>
                     <b style={{ color: '#222' }}>
                        {initiative.discussionStatus?.closedBy.fullName}
                     </b>
                  </Link>
                  {` closed this discussion on ${initiative.discussionStatus?.dateClosed}`}
               </Text>
            </View>
         )}
      </View>
   )
}

DynamicViewPageHome.propTypes = {
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

DynamicViewPageHome.defaultProps = {
   paddingLeft: '0px',
   paddingRight: '0px'
}

export default DynamicViewPageHome
