// =========================================================================================@@
// Last Updated Date: Feb 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { css, keyframes } from 'emotion'
import { Spacer, Text, View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CheckmarkIcon, LikeHeartIcon, MoreIcon } from 'assets/icons'
import { useUpdateMessageLiked } from 'src/core/graphql/hooks'
import FileListItemRowSmall from 'src/sites/kits/Files/components/FileListItemRowSmall'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import { Avatar } from 'src/sites/kits/UI'
import { downloadFileAsset } from 'src/sites/kits/Utils'
import RichText from 'src/sites/kits/Utils/RichText'
import MessagesContext from '~/components/Context'
import MessageInput from '~/components/Input'
import ReplyListItem from '~/components/ReplyListItem'

const animateOut = keyframes`
   0% { opacity: 1; }
   80% { opacity: 1; }
   100% { opacity: 0; }
`

const MessageListItem = ({
   id,
   allowAnonSubmit,
   allowMarkClosingMessage,
   allowMessageCreate,
   anon,
   authorAvatarUrl,
   authorBadges,
   authorId,
   authorName,
   body,
   borderStyle,
   edited,
   flaggedByMe,
   currentUserCanCreate,
   currentUserCanModerate,
   dateAdded,
   isClosingMessage,
   isLikedByMe,
   isMine,
   numLikes,
   onCloseMessageButtonClick,
   replies,
   showNewMessageAnchor,
   textColor
}) => {
   const {
      displayTVRemoteModeControls,
      handleHighlightThreadEntry
   } = useContext(MessagesContext.Context)

   const { updateMessageLiked } = useUpdateMessageLiked()
   const [replyInputIsVisible, setReplyInputIsVisible] = useState(false)

   // TODO: Improve this later
   // We check that the second block (element[1]) is a files block
   // (this is to make sure legacy GNS3 discussions dont blow up)
   // See PR #563, #551
   const hasFileAttachments = body.elements?.[1]?.__typename === 'FileListElement' &&
      body.elements?.[1]?.fileInstances.length > 0

   const handleMessageLikeToggled = async (liked) => {
      await updateMessageLiked({ id, liked })
      // {
      //    optimisticResponse: {
      //       updateMessageLiked: {
      //          __typename: 'HumanMessage',
      //          id,
      //          isLikedByMe: liked,
      //          numLikes: liked
      //             ? numLikes + 1
      //             : numLikes - 1
      //       }
      //    }
      // }
   }

   return (
      <View
         float="left"
         width="100%"
         border={isClosingMessage && '2px solid rgb(69, 194, 145)'}
         borderRadius={isClosingMessage && '2px'}
         borderTop={!isClosingMessage && borderStyle}>
         {isClosingMessage && (
            <View
               display="flex"
               alignItems="center"
               backgroundColor="rgb(69, 194, 145)"
               padding="10px 15px 15px 15px">
               <CheckmarkIcon width="12px" height="12px" color="#fff" />
               <Spacer size="2" orientation="vertical" />
               <Text size="1.5" weight="medium" color="#fff">
                  This answers the question
               </Text>
            </View>
         )}
         <View
            display="flex"
            padding={isClosingMessage
               ? '16px'
               : '16px 0px[a-b] 16px 0px[c-f]'
            }>
            <View
               flex="0 0 auto"
               marginRight="16px"
               marginTop="5px">
               <Avatar
                  fullName={authorName}
                  src={authorAvatarUrl}
                  size="32px"
               />
            </View>
            <View flex="1 1 auto" maxWidth="calc(100% - 50px)">
               <View
                  display="flex"
                  justifyContent="space-between">
                  <View
                     display="block[a-b] flex[c-f]"
                     alignItems="center">
                     <View display="inline-flex" alignItems="center" paddingRight="5px">
                        {!anon && (
                           <Link
                              to={`/-/profile/${authorId}`}
                              className="weight-semibold"
                              style={{ color: 'var(--titleTextColor)' }}>
                              {authorName}
                           </Link>
                        )}
                        {anon && (
                           <div
                              className="weight-semibold"
                              style={{ color: 'var(--titleTextColor)' }}>
                              Anonymous
                           </div>
                        )}
                        {!anon && (
                           <UserBadgeSash badges={authorBadges} style={{ margin: '0 0 0 5px' }} />
                        )}
                     </View>
                     <div className="ui-meta-text">
                        {dateAdded}
                        {edited && (
                           <View display="inline-block" marginLeft="5px">
                              [edited]
                           </View>
                        )}
                     </div>
                     {flaggedByMe && (
                        <View display="inline-block" marginLeft="5px" style={{ color: 'orange' }}>
                           [Reported]
                        </View>
                     )}
                  </View>
                  <MessagesContext.ThreadEntryOptionsButton
                     messageId={id}
                     threadEntryBody={body}
                     threadEntryType="message"
                     userCanEdit={isMine}
                     userCanRemove={isMine || currentUserCanModerate}
                     userCanReport={!isMine}>
                     <MoreIcon
                        width="24px"
                        height="24px"
                        color={textColor}
                     />
                  </MessagesContext.ThreadEntryOptionsButton>
               </View>
               <View display="block" width="100%" maxWidth="700px">
                  <div className="ui-paragraph-text">
                     <RichText html={body?.elements?.[0]?.body} />
                  </div>
                  {hasFileAttachments && (
                     <View
                        float="left"
                        width="100%"
                        margin="15px 0px"
                        borderTop="1px solid var(--primaryLineColor)"
                        borderLeft="1px solid var(--primaryLineColor)"
                        borderRight="1px solid var(--primaryLineColor)">
                        {body.elements[1].fileInstances.map(instance => (
                           <FileListItemRowSmall
                              key={instance.file.id}
                              id={instance.file.id}
                              infected={instance.file.infected}
                              mimetypeMismatch={instance.file.mimetypeMismatch}
                              mimetypeVerified={instance.file.mimetypeVerified}
                              name={instance.file.name}
                              onDownloadButtonClick={() => {
                                 downloadFileAsset(instance.file.id)
                              }}
                              paddingHorizontal="5px"
                              showDownloadButton
                              status={instance.file.status}
                              thumbnailUrl={instance.file.thumbnailUrlW48}
                           />
                        ))}
                     </View>
                  )}
                  {numLikes > 0 && (
                     <View float="left" width="100%" marginTop="6px">
                        <Text
                           size="1"
                           lineHeight="150%"
                           color={textColor}>
                           {`${pluralize('people', numLikes, true)} like${numLikes === 1 ? 's' : ''} this`}
                        </Text>
                     </View>
                  )}
               </View>
               <View float="left" width="100%" display="block" paddingTop="8px">
                  <View
                     className="ui-meta-text weight-semibold"
                     display="flex"
                     alignItems="center"
                     style={{ color: '#999' }}>
                     <View
                        onClick={() => handleMessageLikeToggled(!isLikedByMe)}
                        display="inline-flex"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}>
                        {isLikedByMe && (
                           <View display="inline-block" paddingRight="6px">
                              <LikeHeartIcon width="12px" height="12px" color="#ff5b5b" />
                           </View>
                        )}
                        <div style={{ color: isLikedByMe ? '#ff5b5b' : undefined }}>
                           {isLikedByMe ? 'Liked' : 'Like'}
                        </div>
                     </View>
                     {currentUserCanCreate && allowMessageCreate && !replyInputIsVisible && (
                        <View
                           onClick={() => setReplyInputIsVisible(true)}
                           display="inline-flex"
                           alignItems="center"
                           style={{ cursor: 'pointer' }}>
                           <div style={{ padding: '0px 8px' }}>&#183;</div>
                           Reply
                        </View>
                     )}
                     {displayTVRemoteModeControls && (
                        <View
                           onClick={() => handleHighlightThreadEntry({ entryId: id, entryType: 'message' })}
                           display="inline-flex"
                           alignItems="center"
                           style={{ cursor: 'pointer' }}>
                           <div style={{ padding: '0px 8px' }}>&#183;</div>
                           Highlight on TV
                        </View>
                     )}
                     {(isMine || currentUserCanModerate) && allowMarkClosingMessage && (
                        <View
                           onClick={onCloseMessageButtonClick}
                           display="inline-flex"
                           alignItems="center"
                           opacity="0.5"
                           style={{ cursor: 'pointer' }}>
                           <div style={{ padding: '0px 8px' }}>&#183;</div>
                           Mark as Answer
                        </View>
                     )}
                  </View>
                  {replies.map(reply => (
                     <ReplyListItem
                        key={reply.id}
                        id={reply.id}
                        anon={reply.anon}
                        authorAvatarUrl={reply?.author?.avatarMedia?.file.thumbnailUrlW48}
                        authorBadges={reply?.author?.badges}
                        authorId={reply?.author?.id}
                        authorName={reply?.author?.fullName}
                        body={reply.body?.elements?.[0]?.body}
                        borderStyle={borderStyle}
                        currentUserCanModerate={currentUserCanModerate}
                        dateAdded={reply.dateAdded}
                        edited={reply.edited}
                        flaggedByMe={reply.flaggedByMe}
                        isLikedByMe={reply.isLikedByMe}
                        isMine={reply.isMine}
                        messageId={id}
                        numLikes={reply.numLikes}
                        textColor={textColor}
                     />
                  ))}
                  {replyInputIsVisible && (
                     <View float="left" width="100%" paddingTop="10px">
                        <MessageInput
                           allowAnonSubmit={allowAnonSubmit}
                           inputExpandedOnStart
                           inputToolbarConfig="textBasic"
                           messageId={id}
                           onCancel={() => setReplyInputIsVisible(false)}
                           onSubmit={() => setReplyInputIsVisible(false)}
                        />
                     </View>
                  )}
               </View>
            </View>
         </View>
         {showNewMessageAnchor && (
            <View
               className={css`
                  opacity: 0;
                  animation: ${animateOut} 10s ease-out;
               `}
               position="relative"
               top="1px"
               borderTop="1px solid #3893f2">
               <View
                  display="flex"
                  alignItems="center"
                  height="16px"
                  position="absolute"
                  top="-8px"
                  padding="0px 10px"
                  borderRadius="2px"
                  backgroundColor="#3893f2">
                  <Text size="0.8" weight="medium" transform="uppercase" color="#fff">
                     New
                  </Text>
               </View>
            </View>
         )}
      </View>
   )
}

MessageListItem.propTypes = {
   id: PropTypes.string.isRequired,
   allowAnonSubmit: PropTypes.bool,
   allowMarkClosingMessage: PropTypes.bool,
   allowMessageCreate: PropTypes.bool.isRequired,
   anon: PropTypes.bool.isRequired,
   authorAvatarUrl: PropTypes.string,
   authorBadges: PropTypes.array,
   authorId: PropTypes.string,
   authorName: PropTypes.string,
   body: PropTypes.object.isRequired,
   borderStyle: PropTypes.string,
   currentUserCanCreate: PropTypes.bool.isRequired,
   currentUserCanModerate: PropTypes.bool.isRequired,
   dateAdded: PropTypes.string.isRequired,
   edited: PropTypes.bool.isRequired,
   flaggedByMe: PropTypes.bool,
   isClosingMessage: PropTypes.bool,
   isLikedByMe: PropTypes.bool.isRequired,
   isMine: PropTypes.bool.isRequired,
   numLikes: PropTypes.number.isRequired,
   onCloseMessageButtonClick: PropTypes.func,
   replies: PropTypes.array.isRequired,
   showNewMessageAnchor: PropTypes.bool,
   textColor: PropTypes.string
}

MessageListItem.defaultProps = {
   allowAnonSubmit: false,
   allowMarkClosingMessage: false,
   authorAvatarUrl: undefined,
   authorBadges: [],
   authorId: undefined,
   authorName: undefined,
   borderStyle: '1px solid var(--primaryLineColor)',
   // borderStyle: undefined,
   flaggedByMe: false,
   isClosingMessage: false,
   onCloseMessageButtonClick: undefined,
   showNewMessageAnchor: false,
   textColor: '#222'
}

export default MessageListItem
