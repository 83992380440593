// =========================================================================================@@
// Last Updated Date: Mar 29, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal, View } from 'oio-react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import NestedCustomPage from 'src/sites/kits/Object/apps/Nested/CustomPage'
import PageCreate from 'src/sites/kits/Object/apps/PageCreate'
import ObjectViewBody from 'src/sites/kits/Object/components/ViewBody'
import ObjectViewEventHeader from 'src/sites/kits/Object/components/ViewEventHeader'
import ObjectNav from 'src/sites/kits/Object/components/ViewNav'
import TVFeed from 'src/sites/kits/Object/apps/TV/Feed'
import PollEditor from 'src/sites/kits/Object/apps/PollEditor'
import TVPolls from 'src/sites/kits/Object/apps/TV/Polls'
import TVRemote from 'src/sites/kits/Object/apps/TV/Remote'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { Title } from 'src/sites/kits/UI'
import ScrollView from 'src/sites/kits/Utils/ScrollView'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const SessionView = () => {
   const history = useHistory()
   const match = useRouteMatch()

   const { organization } = useOrganization()
   const { initiative: session, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const allowAnonSubmit = organization.slug === 'banff-pipeline-workshop'

   if (initiativeLoading) {
      return null
   }

   return (
      <>
         <ScrollView
            className="ui-modal"
            position="relative"
            padding="64px var(--gatherAppPaddingHorizontal)[a-b] 64px[c-f]"
            width="100%"
            height="100%"
            scroll="on">
            <ObjectViewEventHeader />
            <ObjectNav />
            <View position="relative">
               <Switch>
                  <Route
                     exact
                     path={match.path}
                     render={() => (
                        <View>
                           <ObjectViewBody />
                           {session.appsMessagesEnabled && (
                              <View float="left" width="100%">
                                 <View width="100%" paddingBottom="20px">
                                    <Title size="xs">
                                       Comments
                                    </Title>
                                 </View>
                                 <MessageFeed
                                    allowAnonSubmit={allowAnonSubmit}
                                    currentUserCanCreate={
                                       session.appsMessagesCurrentUserCanCreate
                                    }
                                    currentUserCanModerate={
                                       session.currentUserCanModerateMessages
                                    }
                                    displayMode="feed"
                                    hasUnreadEntries={session.thread.hasUnreadEntries}
                                    messageLastViewed={session.thread.messageLastViewed}
                                 />
                              </View>
                           )}
                           <View
                              float="left"
                              width="100%"
                              height="90px[d-f]"
                           />
                        </View>
                     )}
                  />
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/poll-editor`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              maxWidth="1200px"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <PollEditor onCloseButtonClick={onCloseTrigger} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/tv-feed`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <TVFeed returnUrl={match.url} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/tv-polls`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <TVPolls returnUrl={match.url} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  {session.currentUserCanModerateMessages && (
                     <ModalRoute path={`${match.path}/-/tv-remote`}>
                        {({ open, onCloseComplete, onCloseTrigger }) => (
                           <Modal
                              borderRadius="6px"
                              overlayBackgroundColor="rgba(0,0,0,0.85)"
                              width="100%"
                              height="100%"
                              onCloseComplete={onCloseComplete}
                              onCloseTrigger={onCloseTrigger}
                              open={open}
                              zIndex="var(--tvViewZindex)">
                              <TVRemote returnUrl={match.url} />
                           </Modal>
                        )}
                     </ModalRoute>
                  )}
                  <Route
                     path={`${match.path}/-/pages/create`}
                     render={() => (
                        <PageCreate
                           onCancelButtonClick={() => history.push(match.url)}
                           onCreate={page => history.push(`${match.url}/${page.slug}`)}
                           paddingLeft="0px"
                           paddingRight="0px"
                        />
                     )}
                  />
                  <Route
                     path={`${match.path}/:pageSlug([a-zA-Z0-9][^/]+)`}
                     render={() => (
                        <NestedCustomPage
                           contentMaxWidth="600px"
                           paddingLeft="0px"
                           paddingRight="0px"
                        />
                     )}
                  />
               </Switch>
            </View>
         </ScrollView>
      </>
   )
}

export default SessionView
