import React from 'react'
import { View } from 'oio-react'
import Logo from 'src/sites/kits/Global/components/MainMenu/Logo'
import { useGlobalState } from 'src/sites/state'
import IconDrawerMenuButton from '~/components/MainMenu/IconDrawerMenuButton'

const MobileMainMenu = () => {
   const { theme } = useGlobalState()

   return (
      <View
         display="flex[a-c] none[d-f]"
         justifyContent="space-between[a-d]"
         alignItems="center"
         width="100%"
         height={theme.tmpHeaderHeight}
         backgroundColor="var(--mainMenuBackgroundColor)"
         paddingHorizontal={theme.tmpViewportPadding}
         borderBottom="1px solid var(--mainMenuLineColor)">
         <View flex="1 1 auto" paddingRight="30px">
            <Logo />
         </View>
         <View flex="0 0 auto" display="flex" alignItems="center">
            <IconDrawerMenuButton
               color="var(--mainMenuButtonTextColor)"
               display="flex"
            />
         </View>
      </View>
   )
}

export default MobileMainMenu
