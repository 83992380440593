// =========================================================================================@@
// Last Updated Date: Mar 31, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import { Route, useRouteMatch } from 'react-router-dom'
import { useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import { SocketProvider } from 'src/core/kits/Utils/SocketProvider'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import ObjectCreateControl from 'src/sites/kits/Object/components/CreateControl'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'
import Note from 'src/sites/kits/Object/apps/Note'
import { LayoutContainerFixedArea, MultiPageContentPane, MultiPageContentPaneInner,
   MultiPageNavPane, Title } from 'src/sites/kits/UI'

const CollectionMedium = () => {
   const match = useRouteMatch()

   const buttonProps = {
      borderRadius: '4px',
      paddingHorizontal: '10px',
      textFontWeight: '500'
   }

   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const mediumInitiativeType = organization.initiativeTypes
      .find(t => t.class === 'medium')

   const { initiativeList } = useInitiativeList({
      parentInitiativeId: initiative?.id
   }, { skip: !initiative?.id })

   if (initiativeLoading) {
      return null
   }

   return (
      <SocketProvider>
         <LayoutContainerFixedArea>
            <MultiPageNavPane>
               <View width="100%" height="calc(100% - var(--toolbarHeight))" scroll="on">
                  <View
                     display="flex"
                     alignItems="center"
                     height="var(--titlebarHeight)"
                     padding="0px 24px">
                     <Title size="md">{initiative.name}</Title>
                  </View>
                  <View
                     paddingLeft="16px"
                     paddingRight="16px">
                     {initiativeList.items.map(medium => (
                        <ListMenuButton
                           key={medium.id}
                           linkTo={medium.gatherUrl}
                           name={medium.name}
                           {...buttonProps}
                        />
                     ))}
                  </View>
               </View>
               <View
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                  height="var(--toolbarHeight)"
                  padding="0px 8px">
                  <ObjectCreateControl
                     buttonHeight="var(--baseComponentHeight-sm)"
                     initiativeTypes={[mediumInitiativeType]}
                     parentInitiativeId={initiative.id}
                  />
               </View>
            </MultiPageNavPane>
            <MultiPageContentPane>
               <MultiPageContentPaneInner>
                  <View>
                     <Route
                        path={`${match.path}/:pageSlug([a-zA-Z0-9][^/]+)`}
                        render={() => (
                           <Note />
                        )}
                     />
                  </View>
               </MultiPageContentPaneInner>
            </MultiPageContentPane>
         </LayoutContainerFixedArea>
      </SocketProvider>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(CollectionMedium)
