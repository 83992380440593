// =========================================================================================@@
// Last Updated Date: Feb 27, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import { Link } from 'react-router-dom'
import { AddIcon, BookmarkOutline24Icon, MessagesOutline24Icon } from 'assets/icons'
import { messagesUrl } from 'config/constants/urls'
import { useMe, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import { Badge } from 'src/sites/kits/UI'

const SidebarActionBlock = () => {
   const { myNotificationCount } = useGlobalState()
   const { isLoggedIn } = useMe()
   const { organization } = useOrganization()
   const showBookmarsButton = ['mother-co', 'banff-pipeline-workshop'].includes(organization.slug)

   return (
      <View id="gather-sidebar-action-block" width="100%">
         <View
            padding="0px 0px 0px 8px"
            marginBottom="16px"
            borderBottom="1px solid var(--primaryLineColor)">
            {/* <Link to="/-/search">
               <View display="flex" height="var(--baseComponentHeight-md)" alignItems="center">
                  <View width="16px" paddingLeft="2px" opacity="0.9">
                     <SearchIcon
                        width="14px"
                        height="14px"
                        strokeWidth="3px"
                        color="var(--listMenuButtonTextColor)"
                     />
                  </View>
                  <View width="16px" height="16px" />
                  Search
               </View>
            </Link> */}
            {isLoggedIn && (
               <Link to={messagesUrl}>
                  <View display="flex" height="var(--baseComponentHeight-md)" alignItems="center">
                     <View width="16px" marginTop="4px" opacity="0.5" paddingLeft="2px">
                        <MessagesOutline24Icon
                           width="14px"
                           height="14px"
                           strokeWidth="2px"
                           color="var(--listMenuButtonTextColor)"
                        />
                     </View>
                     <View width="16px" height="16px" />
                     Messages
                     <View flex="1 1 auto" height="16px" />
                     {myNotificationCount && (
                        <>
                           <Badge
                              textColor="var(--notificationBadgeTextColor)"
                              backgroundColor="var(--notificationBadgeBackgroundColor)">
                              {myNotificationCount}
                           </Badge>
                        </>
                     )}
                  </View>
               </Link>
            )}
            {isLoggedIn && showBookmarsButton && (
               <Link to="/-/bookmarks">
                  <View display="flex" height="var(--baseComponentHeight-md)" alignItems="center">
                     <View width="16px" paddingLeft="2px" marginTop="4px" opacity="0.5">
                        <BookmarkOutline24Icon
                           width="14px"
                           height="14px"
                           strokeWidth="2px"
                           color="var(--listMenuButtonTextColor)"
                        />
                     </View>
                     <View width="16px" height="16px" />
                     Bookmarks
                  </View>
               </Link>
            )}
            {organization.currentUserIsAdmin && (
               <Link to="/-/pages/create">
                  <View display="flex" height="var(--baseComponentHeight-md)" alignItems="center">
                     <View width="16px" paddingLeft="2px" marginTop="4px" opacity="0.5">
                        <AddIcon
                           width="14px"
                           height="14px"
                           strokeWidth="2px"
                           color="var(--listMenuButtonTextColor)"
                        />
                     </View>
                     <View width="16px" height="16px" />
                     New Page
                  </View>
               </Link>
            )}
            {isLoggedIn && <View width="100%" height="16px" />}
         </View>
      </View>
   )
}

export default SidebarActionBlock
