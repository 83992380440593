// =========================================================================================@@
// Last Updated Date: Mar 31, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LikeHeartIcon from 'assets/icons/likeHeart'
import MoreIcon from 'assets/icons/more'
import { Avatar } from 'src/sites/kits/UI'
import RichText from 'src/sites/kits/Utils/RichText'
import { useUpdateMessageReplyLiked } from 'src/core/graphql/hooks'
import MessagesContext from '~/components/Context'

const ChatReplyListItem = ({
   id,
   anon,
   authorAvatarUrl,
   authorId,
   authorName,
   body,
   currentUserCanModerate,
   edited,
   dateAdded,
   isLikedByMe,
   isMine,
   messageId,
   numLikes,
   textColor,
   textLineHeight,
   textSize,
   titleTextColor
}) => {
   const { updateMessageReplyLiked } = useUpdateMessageReplyLiked()

   const handleReplyLikeToggled = async (liked) => {
      await updateMessageReplyLiked({ replyId: id, messageId, liked })
      //    /*, {
      //    optimisticResponse: {
      //       updateMessageReplyLiked: {
      //          __typename: 'MessageReply',
      //          id,
      //          isLikedByMe: liked,
      //          numLikes: liked
      //             ? numLikes + 1
      //             : numLikes - 1
      //       }
      //    }
      // }*/
   }

   return (
      <View
         position="relative"
         float="left"
         width="100%"
         marginTop="16px">
         <View display="flex">
            <View
               flex="0 0 auto"
               marginRight="16px"
               marginTop="5px">
               <Avatar
                  fullName={authorName}
                  src={authorAvatarUrl}
                  size="32px"
               />
            </View>
            <View flex="1 1 auto" maxWidth="calc(100% - 50px)">
               <View display="flex" justifyContent="space-between">
                  <View
                     display="block[a-b] flex[c-f]"
                     alignItems="center">
                     <View paddingRight="5px">
                        {!anon && (
                           <Link
                              to={`/-/profile/${authorId}`}
                              className="weight-semibold"
                              style={{ color: 'var(--titleTextColor)' }}>
                              {authorName}
                           </Link>
                        )}
                        {anon && (
                           <div
                              className="weight-semibold"
                              style={{ color: 'var(--titleTextColor)' }}>
                              Anonymous
                           </div>
                        )}
                     </View>
                     <div className="ui-meta-text">
                        {dateAdded}
                        {edited && (
                           <View display="inline-block" marginLeft="5px">
                              [edited]
                           </View>
                        )}
                     </div>
                  </View>
               </View>
               <View float="left" width="100%" marginTop="2px">
                  <div className="ui-message-text">
                     <RichText html={body} />
                  </div>
               </View>
               {numLikes > 0 && (
                  <View float="left" width="100%" marginTop="6px">
                     <View
                        onClick={() => handleReplyLikeToggled(!isLikedByMe)}
                        display="inline-flex"
                        alignItems="center"
                        padding="0px 5px"
                        height="25px"
                        borderRadius="5px"
                        border={isLikedByMe
                           ? '1px solid #ff5b5b'
                           : '1px solid var(--primaryLineColor)'
                        }
                        marginRight="5px"
                        style={{ cursor: 'pointer' }}>
                        <LikeHeartIcon
                           width="10px"
                           height="10px"
                           color="#ff5b5b"
                        />
                        <View
                           display="inline-flex"
                           justifyContent="center"
                           alignItems="center"
                           marginLeft="5px"
                           opacity="0.7">
                           <Text
                              size="0.9"
                              weight="bold"
                              color={isLikedByMe
                                 ? '#ff5b5b'
                                 : textColor
                              }>
                              {numLikes}
                           </Text>
                        </View>
                     </View>
                  </View>
               )}
            </View>
         </View>
         <View
            display="flex"
            flexFlow="row"
            justifyContent="flex-end"
            alignItems="center"
            width="120px"
            position="absolute"
            top="10px"
            right="5px"
            className="actions">
            {!isMine && !isLikedByMe && (
               <View
                  onClick={() => handleReplyLikeToggled(!isLikedByMe)}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="30px"
                  height="30px"
                  backgroundColor="#fafafa"
                  borderRadius="3px"
                  border="1px solid var(--primaryLineColor)"
                  marginRight="5px"
                  style={{ cursor: 'pointer' }}>
                  <LikeHeartIcon
                     width="12px"
                     height="12px"
                     color={textColor}
                  />
               </View>
            )}
            {/* TODO: Other people with message edit or remove privileges
               should be able to also edit or remove the reply */}
            {(isMine || currentUserCanModerate) && (
               <MessagesContext.ThreadEntryOptionsButton
                  messageId={messageId}
                  replyId={id}
                  threadEntryBody={body}
                  threadEntryType="reply"
                  userCanEdit={isMine}
                  userCanRemove={isMine || currentUserCanModerate}
                  userCanReport={false}>
                  <View
                     display="flex"
                     justifyContent="center"
                     alignItems="center"
                     width="30px"
                     height="30px"
                     backgroundColor="#fafafa"
                     borderRadius="3px"
                     border="1px solid #ddd">
                     <MoreIcon
                        width="24px"
                        height="24px"
                        color={textColor}
                     />
                  </View>
               </MessagesContext.ThreadEntryOptionsButton>
            )}
         </View>
      </View>
   )
}

ChatReplyListItem.propTypes = {
   id: PropTypes.string.isRequired,
   anon: PropTypes.bool.isRequired,
   authorAvatarUrl: PropTypes.string,
   authorId: PropTypes.string,
   authorName: PropTypes.string,
   body: PropTypes.string.isRequired,
   currentUserCanModerate: PropTypes.bool.isRequired,
   edited: PropTypes.bool.isRequired,
   dateAdded: PropTypes.string.isRequired,
   isLikedByMe: PropTypes.bool.isRequired,
   isMine: PropTypes.bool.isRequired,
   messageId: PropTypes.string.isRequired,
   numLikes: PropTypes.number.isRequired,
   textColor: PropTypes.string,
   textLineHeight: PropTypes.string,
   textSize: PropTypes.string,
   titleTextColor: PropTypes.string
}

ChatReplyListItem.defaultProps = {
   authorAvatarUrl: undefined,
   authorId: undefined,
   authorName: undefined,
   textColor: '#222',
   textLineHeight: '160%',
   textSize: '2',
   titleTextColor: '#000'
}

export default ChatReplyListItem
