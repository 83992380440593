import React, { useContext } from 'react'
import { View } from 'oio-react'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectNav from 'src/sites/kits/Object/components/ViewNav'
import ObjectViewRoutes from 'src/sites/kits/Object/apps/DynamicViewPage/Routes'
import { Title } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'

const Group = () => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   return (
      <View
         display="flex"
         flexFlow="column"
         width="100%" height="100vh">
         <View
            width="100%"
            paddingLeft="var(--gatherAppPaddingHorizontal)"
            paddingRight="var(--gatherAppPaddingHorizontal)"
            borderBottom="1px solid var(--primaryLineColor)"
            flex="0 0 auto">
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               height="var(--titlebarHeight)">
               <View width2="20%" flex="0 0 auto" paddingRight="40px">
                  <Title size="md">{initiative.name}</Title>
               </View>
               <View
                  display="flex"
                  justifyContent="flex-end"
                  flex="1 1 auto"
               />
               <View
                  display="flex"
                  justifyContent="flex-end"
                  width2="20%"
                  flex="0 0 auto">
                  <ObjectMoreButton color="transparent" />
               </View>
            </View>
            <ObjectNav alignItems="flex-start" borderStyle="none" height="48px" />
         </View>
         <ObjectViewRoutes
            contentPaddingLeft="0px"
            contentPaddingRight="0px"
         />
      </View>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'initiativeSlug' })(Group)
