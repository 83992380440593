// =========================================================================================@@
// Last Updated Date: Feb 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Text, View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LikeHeartIcon from 'assets/icons/likeHeart'
import MoreIcon from 'assets/icons/more'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import { Avatar } from 'src/sites/kits/UI'
import RichText from 'src/sites/kits/Utils/RichText'
import { useUpdateMessageReplyLiked } from 'src/core/graphql/hooks'
import MessagesContext from '~/components/Context'

const ReplyListItem = ({
   id,
   anon,
   authorAvatarUrl,
   authorBadges,
   authorId,
   authorName,
   body,
   borderStyle,
   currentUserCanModerate,
   dateAdded,
   edited,
   flaggedByMe,
   isLikedByMe,
   isMine,
   messageId,
   numLikes,
   textColor
}) => {
   const {
      displayTVRemoteModeControls,
      handleHighlightThreadEntry
   } = useContext(MessagesContext.Context)

   const { updateMessageReplyLiked } = useUpdateMessageReplyLiked()
   const handleReplyLikeToggled = async (liked) => {
      await updateMessageReplyLiked({ replyId: id, messageId, liked })
      // , {
      //    optimisticResponse: {
      //       updateMessageReplyLiked: {
      //          __typename: 'MessageReply',
      //          id,
      //          isLikedByMe: liked,
      //          numLikes: liked
      //             ? numLikes + 1
      //             : numLikes - 1
      //       }
      //    }
      // })
   }

   return (
      <View
         float="left"
         width="100%"
         marginTop="15px"
         padding="15px 0px 0px 0px"
         borderTop="1px solid var(--primaryLineColor)">
         <View display="flex">
            <View
               flex="0 0 auto"
               marginRight="16px"
               marginTop="5px">
               <Avatar
                  fullName={authorName}
                  src={authorAvatarUrl}
                  size="32px"
               />
            </View>
            <View flex="1 1 auto" maxWidth="calc(100% - 50px)">
               <View display="flex" justifyContent="space-between">
                  <View
                     display="block[a-b] flex[c-f]"
                     alignItems="center">
                     <View display="inline-flex" alignItems="center" paddingRight="5px">
                        {!anon && (
                           <Link
                              to={`/-/profile/${authorId}`}
                              className="weight-semibold"
                              style={{ color: 'var(--titleTextColor)' }}>
                              {authorName}
                           </Link>
                        )}
                        {anon && (
                           <div
                              className="weight-semibold"
                              style={{ color: 'var(--titleTextColor)' }}>
                              Anonymous
                           </div>
                        )}
                        {!anon && (
                           <UserBadgeSash badges={authorBadges} style={{ margin: '0 0 0 5px' }} />
                        )}
                     </View>
                     <div className="ui-meta-text">
                        {dateAdded}
                        {edited && (
                           <View display="inline-block" marginLeft="6px">
                              [edited]
                           </View>
                        )}
                     </div>
                     {flaggedByMe && (
                        <View display="inline-block" marginLeft="5px" style={{ color: 'orange' }}>
                           [Reported]
                        </View>
                     )}
                  </View>
                  <MessagesContext.ThreadEntryOptionsButton
                     messageId={messageId}
                     replyId={id}
                     threadEntryBody={body}
                     threadEntryType="reply"
                     userCanEdit={isMine}
                     userCanRemove={isMine}
                     userCanReport={!isMine}>
                     <MoreIcon
                        width="24px"
                        height="24px"
                        color={textColor}
                     />
                  </MessagesContext.ThreadEntryOptionsButton>
               </View>
               <View float="left" width="100%" className="ui-paragraph-text">
                  <RichText html={body} />
               </View>
               {numLikes > 0 && (
                  <View float="left" width="100%" marginTop="6px">
                     <Text
                        size="1"
                        lineHeight="150%"
                        color={textColor}>
                        {`${pluralize('people', numLikes, true)} like${numLikes === 1 ? 's' : ''} this`}
                     </Text>
                  </View>
               )}
               <View
                  float="left"
                  className="ui-meta-text weight-semibold"
                  display="flex"
                  alignItems="center"
                  width="100%"
                  paddingTop="8px"
                  style={{ color: '#999', cursor: 'pointer' }}>
                  <View
                     onClick={() => handleReplyLikeToggled(!isLikedByMe)}
                     display="inline-flex"
                     alignItems="center">
                     {isLikedByMe && (
                        <View display="inline-block" paddingRight="6px">
                           <LikeHeartIcon width="12px" height="12px" color="#ff5b5b" />
                        </View>
                     )}
                     <div style={{ color: isLikedByMe ? '#ff5b5b' : undefined }}>
                        {isLikedByMe ? 'Liked' : 'Like'}
                     </div>
                  </View>
                  {displayTVRemoteModeControls && (
                     <View
                        onClick={() => handleHighlightThreadEntry({ entryId: id, entryType: 'reply' })}
                        display="inline-flex"
                        alignItems="center">
                        <div style={{ padding: '0px 8px' }}>&#183;</div>
                        Highlight on TV
                     </View>
                  )}
               </View>
            </View>
         </View>
      </View>
   )
}

ReplyListItem.propTypes = {
   id: PropTypes.string.isRequired,
   anon: PropTypes.bool.isRequired,
   authorAvatarUrl: PropTypes.string,
   authorBadges: PropTypes.array,
   authorId: PropTypes.string,
   authorName: PropTypes.string,
   body: PropTypes.string.isRequired,
   borderStyle: PropTypes.string.isRequired,
   currentUserCanModerate: PropTypes.bool.isRequired,
   dateAdded: PropTypes.string.isRequired,
   edited: PropTypes.bool.isRequired,
   flaggedByMe: PropTypes.bool,
   isLikedByMe: PropTypes.bool.isRequired,
   isMine: PropTypes.bool.isRequired,
   messageId: PropTypes.string.isRequired,
   numLikes: PropTypes.number.isRequired,
   textColor: PropTypes.string.isRequired
}

ReplyListItem.defaultProps = {
   authorAvatarUrl: undefined,
   authorBadges: [],
   authorId: undefined,
   authorName: undefined,
   flaggedByMe: false
}

export default ReplyListItem
