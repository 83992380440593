// =========================================================================================@@
// Last Updated Date: Mar 26, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import uiConstants from 'config/constants/ui'
import { useOrganization } from 'src/core/graphql/hooks'
import EyeQNav from 'src/sites/custom/eyeq/UI/Nav'
import { useGlobalState } from 'src/sites/state'
import HeaderActionBlock from '~/components/MainMenu/HeaderActionBlock'
import Logo from '~/components/MainMenu/Logo'
import MainMenuMobile from '~/components/MainMenu/Mobile'
import Nav from '~/components/MainMenu/Nav'

const HeaderLayout = ({ children }) => {
   const { theme } = useGlobalState()
   const { organization } = useOrganization()

   return (
      <>
         <View
            position="fixed"
            top="0px"
            left="0px"
            right="0px"
            display="block[a-c] none[d-f]"
            zIndex={uiConstants.zIndexes.mainMenu}>
            <MainMenuMobile />
         </View>
         <View
            position="fixed"
            display="none[a-c] flex[d-f]"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            height={theme.tmpHeaderHeight}
            backgroundColor="var(--mainMenuBackgroundColor)"
            borderBottom="1px solid var(--mainMenuLineColor)"
            paddingHorizontal={theme.tmpViewportPadding}
            transition="200ms background-color"
            zIndex={uiConstants.zIndexes.mainMenu}>
            {organization.slug === 'eyeq' && <EyeQNav />}
            {organization.slug !== 'eyeq' && (
               <>
                  <View display="flex" alignItems="center" flex="1 1 auto">
                     <Logo />
                     <View width="30px" height="40px" />
                     <Nav display="flex" flex="0 0 auto" orientation="horizontal" />
                  </View>
                  <View display="flex" alignItems="center" flex="0 0 auto">
                     <HeaderActionBlock />
                  </View>
               </>
            )}
         </View>
         <View
            backgroundColor="var(--bodyBackgroundColor)"
            width="calc(100vw - 0px)"
            height="calc(100vh - 0px)"
            display="block[a-c] flex[d-f]"
            scroll="on"
            zIndex="1">
            <View width="100%">
               {children}
            </View>
         </View>
      </>
   )
}

HeaderLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default HeaderLayout
