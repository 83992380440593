import React, { useEffect, useState } from 'react'
import fetch from 'cross-fetch'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
// import * as Sentry from '@sentry/browser'

const Ad = ({ adId, aspectRatio }) => {
   const [adData, setAdData] = useState({})

   const getAd = async () => {
      const apiUrl = `https://servedbyadbutler.com/adserve/;ID=165803;setID=${adId};type=json;click=CLICK_MACRO_PLACEHOLDER`

      try {
         const response = await fetch(apiUrl, { method: 'GET' })
         const result = await response.json()
         const ad = result.placements.placement_1

         setAdData({
            href: ad.redirect_url,
            target: ad.target,
            imgSrc: ad.image_url
         })
      } catch (err) {
         // TODO: This is overloading the Sentry injestion
         // This may need to be throttled?
         // See: https://sentry.io/organizations/motherco/issues/1659868494
         // Sentry.captureException(err)
      }
   }

   useEffect(() => {
      if (adId) {
         getAd()
      }
   }, [])

   return (
      <a href={adData.href} target={adData.target}>
         <View
            position="relative"
            width="100%"
            paddingTop={`${aspectRatio * 100}%`}
            backgroundImage={`url(${adData.imgSrc})`}
            backgroundColor="#aaa"
            backgroundSize="cover"
         />
      </a>
   )
}

Ad.propTypes = {
   adId: PropTypes.string.isRequired,
   aspectRatio: PropTypes.number.isRequired
}

export default Ad
