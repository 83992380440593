import React, { Component } from 'react'
import { Spacer, Text } from 'oio-react'
import PropTypes from 'prop-types'
import { Checkbox, CheckboxGroup, Input, Radio, RadioGroup, Select, Textarea }
   from 'src/sites/kits/Utils/ConnectedForm'
import FormElementContainer from './ElementContainer'

export default class FormView extends Component {
   static propTypes = {
      elements: PropTypes.array.isRequired
   }

   render() {
      const { elements } = this.props

      if (!elements.length) {
         return (
            <center>
               <Text>No elements have been added to this form yet</Text>
            </center>
         )
      }

      if (elements && elements.length) {
         return elements.map((element, index) => {
            const questionNumber = index + 1
            switch (element.type) {
               case 'text':
                  if (element.multiline) {
                     return (
                        <FormElementContainer
                           key={element.id}
                           element={element}
                           number={questionNumber}>
                           <Spacer size="1" />
                           <Textarea
                              name={element.id}
                              placeholder="Your answer"
                              required={element.required}
                           />
                        </FormElementContainer>
                     )
                  }
                  return (
                     <FormElementContainer
                        key={element.id}
                        element={element}
                        number={questionNumber}>
                        <Input
                           name={element.id}
                           placeholder="Your answer"
                           required={element.required}
                        />
                     </FormElementContainer>
                  )

               case 'info':
                  return (
                     <FormElementContainer
                        key={element.id}
                        element={element}
                        number={questionNumber}
                     />
                  )

               case 'radio':
                  return (
                     <FormElementContainer
                        key={element.id}
                        element={element}
                        number={questionNumber}>
                        <Text size="1.5" color="#888">Choose one of the following:</Text>
                        <Spacer size="2" />
                        <RadioGroup
                           name={element.id}
                           required={element.required}>
                           {element.options.map((option, optionIndex) => (
                              <Radio key={option || optionIndex} value={option} label={option} />
                           ))}
                        </RadioGroup>
                        <Spacer size="1" />
                     </FormElementContainer>
                  )

               case 'checkbox':
                  return (
                     <FormElementContainer
                        key={element.id}
                        element={element}
                        number={questionNumber}>
                        <Text size="1.5" color="#888">Choose any of the following:</Text>
                        <Spacer size="2" />
                        <CheckboxGroup
                           name={element.id}
                           required={element.required}>
                           {element.options.map((option, optionIndex) => (
                              <Checkbox key={option || optionIndex} value={option} label={option} />
                           ))}
                        </CheckboxGroup>
                        <Spacer size="1" />
                     </FormElementContainer>
                  )

               case 'dropdown':
                  return (
                     <FormElementContainer
                        key={element.id}
                        element={element}
                        number={questionNumber}>
                        <Text size="1.5" color="#888">Choose one of the following:</Text>
                        <Spacer size="2" />
                        <Select
                           name={element.id}
                           defaultValue=""
                           required={element.required}>
                           <option disabled value="">Please choose an option</option>
                           {element.options.map((option, optionIndex) => (
                              <option key={option || optionIndex} value={option}>
                                 {option}
                              </option>
                           ))}
                        </Select>
                        <Spacer size="1" />
                     </FormElementContainer>
                  )

               default:
                  return null
            }
         })
      }
   }
}
