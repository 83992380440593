// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Ajay
// Status Level: 2
// ===========================================================================================

import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, ListMenu, ListMenuButton, Modal, Popover, Spacer, Text, View } from 'oio-react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { arrayMove } from 'react-sortable-hoc'
import scrollToWithAnimation from 'scrollto-with-animation'
import CloseIcon from 'assets/icons/close'
import FormIcon from 'assets/icons/form'
import RichText from 'src/sites/kits/Utils/RichText'
import FormBuilderCardContainer from './CardContainer'
import FormBuilderPreview from './Preview'

const FormBuilder = ({
   addButtonName,
   appPrimaryColor,
   elementTypes,
   elements: initialElements,
   formBuilderExtraActions,
   formBuilderSubtitle,
   formBuilderTitle,
   formSubtitle,
   formTitle,
   onChange,
   onCloseButtonClick,
   previewComponent: PreviewComponent
}) => {
   const history = useHistory()
   const match = useRouteMatch()
   const addElementPopoverControl = useRef()
   const view = useRef()

   const [elements, setElements] = useState(initialElements)
   const [actionsPopoverIsOpen, setActionsPopoverIsOpen] = useState(false)
   const [previewIsVisible, setPreviewIsVisible] = useState(true)
   const [updating, setUpdating] = useState(false)

   useEffect(() => {
      setElements(initialElements)
   }, [initialElements])

   const handleCardAdd = (options) => {
      setElements(currentElementsState => ([
         ...currentElementsState,
         options
      ]))

      scrollToWithAnimation(
         view.current.scrollContainer,
         'scrollTop',
         view.current.scrollContainer.scrollHeight,
         1500,
         'linearTween'
      )
   }

   const handleCardInputChange = (elementIndex, inputName, event) => {
      const eventTargetValue = event.target.value
      setElements(currentElementsState => currentElementsState.map((element, i) => (
         i === elementIndex
            ? { ...element, [inputName]: eventTargetValue }
            : element
      )))
   }

   const handleCardOptionAdd = (elementIndex) => {
      setElements(currentElementsState => currentElementsState.map((element, i) => (
         i === elementIndex
            ? { ...element, options: [...element.options, ''] }
            : element
      )))
   }

   const handleCardOptionChange = (targetElementIndex, targetOptionIndex, event) => {
      const eventTargetValue = event.target.value
      setElements(currentElementsState => currentElementsState.map((element, elementIndex) => (
         elementIndex === targetElementIndex
            ? {
               ...element,
               options: element.options.map((option, optionIdex) => (
                  optionIdex === targetOptionIndex
                     ? eventTargetValue
                     : option
               ))
            }
            : element
      )))
   }

   const handleCardOptionRemove = (elementIndex, optionIndex) => {
      setElements(currentElementsState => currentElementsState.map((element, i) => (
         i === elementIndex
            ? {
               ...element,
               options: [
                  ...element.options.slice(0, optionIndex),
                  ...element.options.slice(optionIndex + 1)
               ]
            }
            : element
      )))
   }

   const handleCardOptionSortEnd = ({ oldIndex, newIndex, collection }) => {
      setElements(currentElementsState => currentElementsState.map((element, index) => (
         index === collection
            ? { ...element, options: arrayMove(element.options.slice(), oldIndex, newIndex) }
            : element
      )))
   }

   const handleCardRemove = (elementIndex) => {
      setElements(currentElementsState => ([
         ...currentElementsState.slice(0, elementIndex),
         ...currentElementsState.slice(elementIndex + 1)
      ]))
   }

   const handleCardRequiredChange = (elementIndex, event) => {
      const isChecked = event.target.checked
      setElements(currentElementsState => currentElementsState.map((element, i) => (
         i === elementIndex
            ? { ...element, required: isChecked }
            : element
      )))
   }

   const handleCardSortEnd = ({ oldIndex, newIndex }) => {
      setElements(currentElementsState => (
         arrayMove(currentElementsState.slice(), oldIndex, newIndex)
      ))
   }

   const handleSubmit = async () => {
      // TODO: Kinda dumb that `updating` state relies on `onChange` waiting
      // for a promise to resolve
      setUpdating(true)
      await onChange(elements)
      setUpdating(false)
   }

   return (
      <View width="100%" height="100%" borderRadius="6px" style={{ overflow: 'hidden' }}>
         <View
            float="left"
            width="300px"
            height="100%"
            padding="30px">
            <View
               display="flex"
               justifyContent="center"
               alignItems="center"
               width="36px"
               height="36px"
               borderRadius="50%"
               backgroundColor="#000">
               <FormIcon
                  width="20px"
                  height="20px"
                  strokeWidth="2px"
                  color="#fff"
               />
            </View>
            <Spacer size="2" />
            <Text size="5" color="#000" weight="medium">
               {formTitle}
            </Text>
            <Text size="4" color="#000">
               {formBuilderTitle}
            </Text>
            <Spacer size="6" />
            <View float="left" width="100%" marginBottom="30px">
               <Text size="2" color="#777">
                  <RichText html={formBuilderSubtitle} />
               </Text>
            </View>
            {formBuilderExtraActions}
            <Spacer size="2" />
            <Button
               mode={updating ? 'loading' : 'normal'}
               width="100%"
               onClick={handleSubmit}
               name="Save"
               size="md"
               color="#eee"
               textColor="#222"
            />
            <Spacer size="1" />
            <Button
               width="100%"
               ref={addElementPopoverControl}
               icon="ion-plus-circled"
               name={addButtonName}
               onClick={() => setActionsPopoverIsOpen(true)}
               size="md"
               color="#eee"
               textColor="#222"
            />
            <Spacer size="1" />
            {PreviewComponent && (
               <Button
                  linkTo={`${match.url}/preview`}
                  width="100%"
                  icon="ion-eye"
                  name="Preview Form"
                  size="md"
                  color="#eee"
                  textColor="#222"
                  style={{ position: 'static' }}
               />
            )}
            <Popover
               anchorElement={addElementPopoverControl.current}
               anchorOriginHorizontal="left"
               width="240px"
               onBodyClick={() => setActionsPopoverIsOpen(false)}
               open={actionsPopoverIsOpen}>
               <View
                  float="left"
                  width="100%"
                  backgroundColor="#000"
                  boxShadow="3px 3px 12px rgba(0,0,0,0.3)"
                  borderRadius="3px">
                  <ListMenu
                     buttonSize="md"
                     buttonTextColor="#fff"
                     dividerLineStyle="1px solid #222"
                     buttonPadding="12px">
                     {elementTypes.includes('info') && (
                        <ListMenuButton
                           onClick={() => handleCardAdd({
                              type: 'info'
                           })}
                           name="Text Explanation or Information"
                        />
                     )}
                     {elementTypes.includes('text') && (
                        <ListMenuButton
                           onClick={() => handleCardAdd({
                              type: 'text'
                           })}
                           name="Single-line Text Input"
                        />
                     )}
                     {elementTypes.includes('paragraph') && (
                        <ListMenuButton
                           onClick={() => handleCardAdd({
                              type: 'text',
                              multiline: true
                           })}
                           name="Paragraph Text Input"
                        />
                     )}
                     {elementTypes.includes('radio') && (
                        <ListMenuButton
                           onClick={() => handleCardAdd({
                              type: 'radio',
                              options: ['']
                           })}
                           name="Multiple Choice"
                        />
                     )}
                     {elementTypes.includes('checkbox') && (
                        <ListMenuButton
                           onClick={() => handleCardAdd({
                              type: 'checkbox',
                              options: ['']
                           })}
                           name="Checkboxes"
                        />
                     )}
                     {elementTypes.includes('dropdown') && (
                        <ListMenuButton
                           onClick={() => handleCardAdd({
                              type: 'dropdown',
                              options: ['']
                           })}
                           name="Dropdown"
                        />
                     )}
                  </ListMenu>
               </View>
            </Popover>
         </View>
         <View
            float="left"
            width="calc(100% - 300px)"
            height="100%"
            backgroundColor="#eee">
            <View
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               float="left"
               width="100%"
               height="60px"
               padding="0px 24px">
               <Text size="1.5" weight="medium">
                  {`This form currently has ${elements.length} ${pluralize('element', elements.length)}`}
               </Text>
               <View
                  width="30px"
                  flex="0 0 auto"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  onClick={onCloseButtonClick}>
                  <CloseIcon width="24px" height="24px" />
               </View>
            </View>
            <View height="calc(100% - 60px)" width="100%">
               {elements.length > 0 && (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     elementFocusBackgroundColor="rgba(250, 147, 104, 0.1)"
                     style={{ width: '100%', height: '100%' }}>
                     <FormBuilderCardContainer
                        ref={view}
                        elements={elements}
                        onInputChange={handleCardInputChange}
                        onOptionAdd={handleCardOptionAdd}
                        onOptionChange={handleCardOptionChange}
                        onOptionRemove={handleCardOptionRemove}
                        onOptionSortEnd={handleCardOptionSortEnd}
                        onRequiredChange={handleCardRequiredChange}
                        onRemove={handleCardRemove}
                        onSortEnd={handleCardSortEnd}
                        useDragHandle
                     />
                  </Form>
               )}
               {!elements.length && (
                  <View width="100%" padding="60px 120px" textAlign="center">
                     <Text size="2" color="#333">
                        Your form currently has no fields, please click the
                        <b>{` ${addButtonName} `}</b>
                        button to get started
                     </Text>
                  </View>
               )}
            </View>
         </View>
         {PreviewComponent && (
            <Switch>
               <Route
                  path={`${match.url}/preview`}
                  render={() => (
                     <Modal
                        borderRadius="6px"
                        overlayBackgroundColor="rgba(20,20,20,0.9)"
                        width="100%[a-b] 90%[c] 540px[d-f]"
                        height="100%[a-b] 98%[c-f]"
                        onCloseComplete={() => {
                           history.push(match.url)
                           setPreviewIsVisible(true)
                        }}
                        onCloseTrigger={() => setPreviewIsVisible(false)}
                        open={previewIsVisible}
                        zIndex="var(--settingsModalLevel3ZIndex)">
                        <PreviewComponent
                           elements={elements}
                           onCloseButtonClick={() => setPreviewIsVisible(false)}
                           subtitle={formSubtitle}
                           title={formTitle}
                        />
                     </Modal>
                  )}
               />
            </Switch>
         )}
      </View>
   )
}

FormBuilder.propTypes = {
   addButtonName: PropTypes.string,
   appPrimaryColor: PropTypes.string,
   elementTypes: PropTypes.array,
   elements: PropTypes.array,
   formBuilderExtraActions: PropTypes.node,
   formBuilderSubtitle: PropTypes.string,
   formBuilderTitle: PropTypes.string,
   formSubtitle: PropTypes.string.isRequired,
   formTitle: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
   onCloseButtonClick: PropTypes.func.isRequired,
   previewComponent: PropTypes.oneOfType([
      PropTypes.instanceOf(React.Component),
      PropTypes.func
   ])
}

FormBuilder.defaultProps = {
   addButtonName: 'Add Element',
   appPrimaryColor: '#d14c2f',
   elementTypes: ['info', 'text', 'paragraph', 'radio', 'checkbox', 'dropdown'],
   elements: [],
   formBuilderExtraActions: null,
   formBuilderSubtitle: 'This app will help you build a form',
   formBuilderTitle: 'Form Builder',
   previewComponent: FormBuilderPreview
}

export default FormBuilder
