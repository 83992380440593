import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, Modal, NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { useCreateInitiative, useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'

const ObjectCreate = ({
   buttonColor,
   buttonTextColor,
   humanizedTypeNameSingular: customHumanizedTypeNameSingular,
   initiativeTypeId,
   modalOnCloseComplete,
   modalOnCloseTrigger,
   modalOpen,
   modalWidth,
   nameInputMaxLength,
   onCreate,
   parentInitiativeId,
   privacy
}) => {
   const [currentInputCharLength, setCurrentInputCharLength] = useState(0)
   const { theme } = useGlobalState()
   const { showNotification } = useContext(NotificationManagerContext)

   const { organization } = useOrganization()
   const { createInitiative } = useCreateInitiative()
   const initiativeType = organization.initiativeTypes.find(t => t.id === initiativeTypeId)
   const humanizedTypeNameSingular = customHumanizedTypeNameSingular || initiativeType.nameSingular

   const isDiscussionOrPost = ['discussion', 'post'].includes(initiativeType.class)
   const [initiativeNameLabel, submitButtonName] = isDiscussionOrPost
      ? ['title', 'Next']
      : ['name', 'Create']

   const handleCreateInitiative = async (values, actions) => {
      try {
         const result = await createInitiative({
            ...values,
            parentInitiativeId,
            privacy: initiativeType.class === 'medium' ? 'inherit' : privacy,
            typeId: initiativeTypeId
         })

         if (onCreate) {
            onCreate(result.data.createInitiative)
         }
      } catch (err) {
         showNotification({
            message: err?.graphQLErrors?.map(e => e.message).join(', ') || err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   return (
      <Modal
         borderRadius="5px"
         width={modalWidth}
         onCloseComplete={modalOnCloseComplete}
         onCloseTrigger={modalOnCloseTrigger}
         open={modalOpen}>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="15px"
            borderBottom="1px solid #eee">
            <Text size="3" weight="medium">
               {`Create New ${humanizedTypeNameSingular}`}
            </Text>
         </View>
         <Formik
            initialValues={{ name: '' }}
            onSubmit={handleCreateInitiative}
            render={({ handleSubmit, handleChange, isSubmitting }) => (
               <Form
                  elementAppearance="plain"
                  elementBackgroundColor="#eee"
                  elementFocusBackgroundColor="#f5f5f5"
                  onSubmit={handleSubmit}>
                  <View padding="15px">
                     <View>
                        <Text size="2" color="#444" weight="medium">
                           {`Please enter a ${initiativeNameLabel} for this new ${initiativeType.nameSingular}`}
                        </Text>
                        <Text size="2" color="#888">
                           {`(${currentInputCharLength} of ${nameInputMaxLength} max characters)`}
                        </Text>
                        <Spacer size="2" />
                        <Input
                           maxLength={nameInputMaxLength}
                           name="name"
                           size="lg"
                           onChange={(e) => {
                              handleChange(e)
                              setCurrentInputCharLength(e.target.value.length)
                           }}
                           placeholder={`${initiativeType.nameSingular} ${initiativeNameLabel}`}
                           required
                        />
                     </View>
                     <Spacer size="3" />
                     <View display="flex" width="100%" justifyContent="flex-end">
                        <View
                           width="100%"
                           maxWidth="270px"
                           display="flex"
                           justifyContent="space-between">
                           <Button
                              onClick={modalOnCloseTrigger}
                              width="49%"
                              name="Cancel"
                              color="#fff"
                              size="md"
                              textColor="#666"
                           />
                           <Button
                              width="49%"
                              type="submit"
                              name={submitButtonName}
                              color={buttonColor || theme.protoSettingsHighlightBackgroundColor}
                              size="md"
                              textColor={buttonTextColor ||
                                 theme.protoSettingsHighlightForegroundColor
                              }
                              mode={isSubmitting ? 'loading' : 'normal'}
                           />
                        </View>
                     </View>
                  </View>
               </Form>
            )}
         />
      </Modal>
   )
}

ObjectCreate.propTypes = {
   buttonColor: PropTypes.string,
   buttonTextColor: PropTypes.string,
   humanizedTypeNameSingular: PropTypes.string,
   initiativeTypeId: PropTypes.string.isRequired,
   modalOnCloseComplete: PropTypes.func.isRequired,
   modalOnCloseTrigger: PropTypes.func.isRequired,
   modalOpen: PropTypes.bool.isRequired,
   modalWidth: PropTypes.string,
   nameInputMaxLength: PropTypes.string,
   onCreate: PropTypes.func,
   parentInitiativeId: PropTypes.string,
   privacy: PropTypes.string
}

ObjectCreate.defaultProps = {
   buttonColor: undefined,
   buttonTextColor: undefined,
   humanizedTypeNameSingular: undefined,
   modalWidth: '90%[a-b] 300px[c-f]',
   nameInputMaxLength: '120',
   onCreate: undefined,
   parentInitiativeId: undefined,
   privacy: undefined
}

export default ObjectCreate
