// =========================================================================================@@
// Last Updated Date: Mar 31, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { AddIcon } from 'assets/icons'
import { initiativeSettingsUrl } from 'config/constants/urls'
import EventCreate from 'src/sites/kits/Events/apps/Create'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import ObjectCreate from 'src/sites/kits/Object/apps/Create'
import { Button, Tooltip } from 'src/sites/kits/UI'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { useOrganization } from 'src/core/graphql/hooks'

// TODO: There is a known weirdness where if this component is inside of the route
// /-/manage-content, then you will have two dashes in the route: /-/manage-content/-/create
// See: #993
const ObjectCreateControl = ({
   initiativeTypes,
   parentInitiativeId
}) => {
   const history = useHistory()
   const match = useRouteMatch()
   const [popoverOpen, setPopoverOpen] = useState(false)
   const { organization } = useOrganization()
   const currentUserCanCreate = initiativeTypes.some(t => t.currentUserCanCreate)

   if (!currentUserCanCreate) {
      return null
   }

   return (
      <>
         {initiativeTypes.length === 1 && (
            <Tooltip text={`New ${initiativeTypes[0].nameSingular}`}>
               <Button
                  linkTo={`${match.url}/-/create/${initiativeTypes[0].id}`}
                  color="transparent"
                  width="var(--baseComponentHeight-md)"
                  height="var(--baseComponentHeight-md)"
                  paddingHorizontal="0px">
                  <AddIcon
                     color="var(--listMenuButtonTextColor)"
                     width="18px"
                     height="18px"
                     strokeWidth="2px"
                  />
               </Button>
            </Tooltip>
         )}
         {initiativeTypes.length > 1 && (
            <DropdownMenu.Root
               open={popoverOpen}
               onOpenChange={open => setPopoverOpen(open)}>
               <DropdownMenu.Trigger>
                  <Tooltip text="Create New">
                     <Button
                        color="transparent"
                        width="var(--baseComponentHeight-md)"
                        height="var(--baseComponentHeight-md)"
                        paddingHorizontal="0px">
                        <AddIcon
                           color="var(--listMenuButtonTextColor)"
                           width="18px"
                           height="18px"
                           strokeWidth="2px"
                        />
                     </Button>
                  </Tooltip>
               </DropdownMenu.Trigger>
               <DropdownMenu.Portal>
                  <DropdownMenu.Content
                     className="ui-popover"
                     collisionPadding={16}>
                     <View onClick={() => setPopoverOpen(false)} width="200px">
                        {initiativeTypes.map(type => (
                           <ListMenuButton
                              key={type.id}
                              name={`New ${type.nameSingular}`}
                              linkTo={`${match.url}/-/create/${type.id}`}
                              paddingHorizontal="8px"
                           />
                        ))}
                     </View>
                  </DropdownMenu.Content>
               </DropdownMenu.Portal>
            </DropdownMenu.Root>
         )}
         <ModalRoute path={`${match.path}/-/create/:initiativeTypeId`}>
            {({ match: modalRouteMatch, open, onCloseComplete, onCloseTrigger }) => {
               const initiativeType = organization.initiativeTypes
                  .find(type => type.id === modalRouteMatch.params.initiativeTypeId)

               if (initiativeType.class !== 'event') {
                  return (
                     <ObjectCreate
                        initiativeTypeId={initiativeType.id}
                        modalOnCloseComplete={onCloseComplete}
                        modalOnCloseTrigger={onCloseTrigger}
                        modalOpen={open}
                        onCreate={(initiative) => {
                           onCloseTrigger()
                           history.push(initiativeSettingsUrl(initiative))
                        }}
                        parentInitiativeId={parentInitiativeId}
                     />
                  )
               }

               return (
                  <Modal
                     borderRadius="3px"
                     width="300px"
                     onCloseTrigger={onCloseTrigger}
                     onCloseComplete={onCloseComplete}
                     open={open}>
                     {initiativeType.class === 'event' && (
                        <EventCreate
                           initiativeTypeId={modalRouteMatch.params.initiativeTypeId}
                           onCancelButtonClick={onCloseTrigger}
                           onCloseTrigger={onCloseTrigger}
                           onCreate={(initiative) => {
                              onCloseTrigger()
                              history.push(initiativeSettingsUrl(initiative))
                           }}
                           parentInitiativeId={parentInitiativeId}
                        />
                     )}
                  </Modal>
               )
            }}
         </ModalRoute>
      </>
   )
}

ObjectCreateControl.propTypes = {
   initiativeTypes: PropTypes.array.isRequired,
   parentInitiativeId: PropTypes.string
}

ObjectCreateControl.defaultProps = {
   initiativeTypes: PropTypes.array.isRequired,
   parentInitiativeId: undefined
}

export default ObjectCreateControl
