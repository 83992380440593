import React, { useMemo, useState } from 'react'
import fetch from 'cross-fetch'
import { Grid, GridCell, Spacer, Text, View } from 'oio-react'
import { Link } from 'react-router-dom'
import SearchInput from 'src/sites/kits/Search/components/Input'
import SearchListItemRow from 'src/sites/kits/Search/components/ListItemRow'
import { calculateMonthsAgo } from 'src/sites/kits/Utils'
import HorizontalAdBanner from 'src/sites/custom/gns3/Banners/HorizontalAd'
import { useInitiativeTypes, useOrganization, useSearchInitiatives } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import AlgoliaSearchResultListItem from './AlgoliaSearchResultListItem'

const algoliaAppId = '1WIL6RAPZV'
const algoliaApiKey = '75bf7dd136b92cb76d52b7ef8de3576f'
const algoliaApiUrl = `https://${algoliaAppId}-dsn.algolia.net/1/indexes/*/queries`

const Search = () => {
   const [activeSearchValue, setActiveSearchValue] = useState('')
   const [algoliaSearchResults, setAlgoliaSearchResults] = useState([])
   const [searchDateFromMonthsAgo, setSearchDateFromMonthsAgo] = useState(12)
   const { theme } = useGlobalState()
   const { organization } = useOrganization()

   const targetedSearchTerms = organization.metadata
      .find(m => m.key === 'targeted_search_terms')?.value

   const targetedSearchTermMatch = Array.isArray(targetedSearchTerms) &&
      targetedSearchTerms.find(term => activeSearchValue.includes(term?.keyword))

   const enabledInitiativeTypes = useInitiativeTypes()
   const enabledInitiativeTypeIds = enabledInitiativeTypes.map(t => t.id)

   const searchFilter = useMemo(() => {
      const filter = {
         initiativeTypeIds: enabledInitiativeTypeIds,
         search: activeSearchValue
      }

      if (searchDateFromMonthsAgo) {
         const today = new Date()
         const searchDateFrom = calculateMonthsAgo(today, searchDateFromMonthsAgo)
         const searchDateFromMonth = searchDateFrom.getMonth()
         const searchDateFromYear = searchDateFrom.getFullYear()

         filter.dateCreated = {
            from: (new Date(Date.UTC(searchDateFromYear, searchDateFromMonth, 1))).toISOString()
         }
      }

      return filter
   }, [organization, activeSearchValue, searchDateFromMonthsAgo])

   // Search Gather
   const { searchInitiatives: searchResults, loading } = useSearchInitiatives(searchFilter, {
      skip: activeSearchValue.trim() === ''
   })

   // Search Algolia
   const searchAlgolia = async (searchValue) => {
      const payload = {
         requests: [{
            indexName: 'gns3',
            query: searchValue
         }]
      }

      const response = await fetch(algoliaApiUrl, {
         method: 'POST',
         headers: {
            'X-Algolia-Application-Id': algoliaAppId,
            'X-Algolia-API-Key': algoliaApiKey
         },
         body: JSON.stringify(payload)
      })

      const result = await response.json()
      setAlgoliaSearchResults(result.results[0].hits)
   }

   return (
      <View
         marginTop={theme.viewportMarginTop}
         width="100%">
         <View
            paddingLeft={theme.viewportMarginLeft}
            paddingRight={theme.viewportMarginRight}
            paddingBottom="50px"
            width="100%"
            minHeight="100vh"
            backgroundColor="#eee">
            <View
               display="flex"
               justifyContent="center"
               width="100%"
               backgroundColor="#0e1624"
               marginBottom="30px">
               <View
                  width="100%"
                  maxWidth={theme.viewportContentMaxWidth}
                  paddingRight={theme.viewportPaddingRight}
                  paddingLeft={theme.viewportPaddingLeft}>
                  <SearchInput
                     backgroundColor="rgba(255,255,255,0.1)"
                     onSubmit={(searchVal) => {
                        setActiveSearchValue(searchVal)
                        searchAlgolia(searchVal)
                     }}
                     onDateRangeChange={setSearchDateFromMonthsAgo}
                     searchDateFromMonthsAgo={searchDateFromMonthsAgo}
                     textColor="#fff"
                  />
               </View>
            </View>
            <View
               display="flex"
               justifyContent="center"
               width="100%">
               <View
                  width="100%"
                  maxWidth={theme.viewportContentMaxWidth}
                  paddingRight={theme.viewportPaddingRight}
                  paddingLeft={theme.viewportPaddingLeft}>
                  {!activeSearchValue && (
                     <View
                        backgroundColor="#fff"
                        borderRadius="5px"
                        width="100%"
                        padding="50px"
                        textAlign="center">
                        <Text size="2.5" color="#888">
                           Enter a search term to begin
                        </Text>
                     </View>
                  )}
                  <View
                     display={activeSearchValue ? 'block' : 'none'}
                     width="100%">
                     <Grid columns="1[a-c] 2[d-f]" spacing="20px[a-d] 30px[e-f]">
                        {targetedSearchTermMatch && (
                           <GridCell colspan="1[a-c] 2[d-f]">
                              <HorizontalAdBanner
                                 buttonName={targetedSearchTermMatch.button_name}
                                 message={targetedSearchTermMatch.message}
                                 url={targetedSearchTermMatch.url}
                              />
                           </GridCell>
                        )}
                        <GridCell>
                           <View
                              float="left"
                              width="100%"
                              padding="20px[a-d] 30px[e-f]"
                              backgroundColor="#fff"
                              borderRadius="5px"
                              minHeight="200px[a-c] 400px[d-f]"
                              border="1px solid #eee">
                              <Text size="3[a-d] 4[e-f]" weight="medium">
                                 GNS3 Documentation Results
                              </Text>
                              <Spacer size="6" />
                              {algoliaSearchResults.map((result) => {
                                 // Default Body
                                 let body = result._highlightResult?.hierarchy?.lvl0?.value

                                 // Best Body
                                 if (result._highlightResult?.content?.value) {
                                    body = result._highlightResult?.content?.value
                                 // Second-best Body
                                 } else if (result._highlightResult?.hierarchy?.lvl1?.value) {
                                    body = result._highlightResult?.hierarchy?.lvl1?.value
                                 }

                                 return (
                                    <AlgoliaSearchResultListItem
                                       key={result.objectID}
                                       body={body}
                                       category={result.hierarchy.lvl1 || result.hierarchy.lvl0}
                                       subtitle={result._highlightResult?.hierarchy?.lvl2?.value ||
                                          result._highlightResult?.hierarchy?.lvl1?.value}
                                       title={result._highlightResult?.hierarchy?.lvl0?.value ||
                                          result.hierarchy.lvl0}
                                       url={result.url}
                                    />
                                 )
                              })}
                              {algoliaSearchResults.length === 0 && (
                                 <View width="100%" padding="30px" textAlign="center">
                                    <Text size="2.5" color="#888">
                                       {'There were no results found for '}
                                       <Text
                                          size="2.5"
                                          color="#333"
                                          weight="medium"
                                          style={{
                                             display: 'inline-block'
                                          }}>
                                          {activeSearchValue}
                                       </Text>
                                       {' in the GNS3 Documentation'}
                                    </Text>
                                 </View>
                              )}
                           </View>
                        </GridCell>
                        <GridCell>
                           <View
                              width="100%"
                              padding="20px[a-d] 30px[e-f]"
                              backgroundColor="#fff"
                              borderRadius="5px"
                              minHeight="200px[a-c] 400px[d-f]"
                              border="1px solid #eee">
                              <Text size="3[a-d] 4[e-f]" weight="medium">
                                 GNS3 Community Results
                              </Text>
                              <Spacer size="1" />
                              {loading && (
                                 <View width="100%" padding="30px" textAlign="center">
                                    <Text
                                       size="2.5"
                                       color="#333"
                                       weight="medium"
                                       style={{
                                          display: 'inline-block'
                                       }}>
                                          Searching...
                                    </Text>
                                 </View>
                              )}
                              {!loading && activeSearchValue &&
                              searchResults.items.length === 0 && (
                                 <View width="100%" padding="30px" textAlign="center">
                                    <Text size="2.5" color="#888">
                                       {'There were no results found for '}
                                       <Text
                                          size="2.5"
                                          color="#333"
                                          weight="medium"
                                          style={{
                                             display: 'inline-block'
                                          }}>
                                          {activeSearchValue}
                                       </Text>
                                       {' in the GNS3 community'}
                                    </Text>
                                 </View>
                              )}
                              {activeSearchValue && searchResults.items.length > 0 && (
                                 <>
                                    <View paddingBottom="50px">
                                       <Text size="2[a-b] 2.5[c-f]" color="#888">
                                          {`Showing ${searchResults.items.length} results for `}
                                          <Text
                                             size="2[a-b] 2.5[c-f]"
                                             color="#333"
                                             weight="medium"
                                             style={{
                                                display: 'inline-block'
                                             }}>
                                             {activeSearchValue}
                                          </Text>
                                       </Text>
                                    </View>
                                    {searchResults.items.map(({ matches, value: initiative }) => (
                                       <Link
                                          key={initiative.id}
                                          to={initiative.gatherUrl}>
                                          <SearchListItemRow
                                             dateAdded={initiative.dateAdded}
                                             matches={matches}
                                             label={initiative.type.nameSingular}
                                             numThreadMessages={initiative.thread.numEntries}
                                             numViews={initiative.numViews}
                                             summary={initiative.body.summary}
                                             title={initiative.name}
                                          />
                                       </Link>
                                    ))}
                                 </>
                              )}
                           </View>
                        </GridCell>
                     </Grid>
                  </View>
               </View>
            </View>
         </View>
      </View>
   )
}

export default Search
