import React from 'react'
import { Spacer, Text, View } from 'oio-react'
import AnnouncementIcon from 'src/sites/custom/gns3/icons/announcement'
import { useOrganization } from 'src/core/graphql/hooks'

const DiscussionAdBanner = () => {
   const { organization } = useOrganization()
   const discussionAdBannerData = organization.metadata
      .find(m => m.key === 'discussion_ad_banner')?.value

   const {
      button_name: buttonName,
      enabled,
      message,
      url
   } = discussionAdBannerData

   if (typeof discussionAdBannerData !== 'object' || !enabled || !url) {
      return null
   }

   return (
      <a href={url} target="_blank" rel="noopener noreferrer">
         <View
            float="left"
            width="100%"
            marginTop="25px"
            paddingTop="20px"
            borderTop="1px solid rgba(0,0,0,0.1)"
            marginBottom="20px"
            borderRadius="6px">
            <Text
               size="0.7"
               weight="medium"
               transform="uppercase"
               color="#aaa">
               Special Offer
            </Text>
            <Spacer size="2" />
            <View display="flex" alignItems="flex-start">
               <View marginRight="10px">
                  <AnnouncementIcon width="14px" height="14px" color="orange" />
               </View>
               <View flex="1 1 auto">
                  <View marginBottom="3px">
                     <Text size="1.5" color="#777" weight="medium">
                        {message}
                     </Text>
                  </View>
                  <Text size="1.5" color="#999">
                     <u>
                        {buttonName || 'Get it now!'}
                     </u>
                  </Text>
               </View>
            </View>
         </View>
      </a>
   )
}

export default DiscussionAdBanner
