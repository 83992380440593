// =========================================================================================@@
// Last Updated Date: Jan 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { breakpoints, Text, View } from 'oio-react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { InboxGlyph16Icon } from 'assets/icons'
import { LAYOUT_HEADER } from 'config/constants/ui'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import Inbox from 'src/sites/kits/Messages/apps/Inbox'
import InitiativeThreadChat from 'src/sites/kits/Messages/apps/InitiativeThreadChat'
import UserThreadChat from 'src/sites/kits/Messages/apps/UserThreadChat'
import { LayoutContainerFixedArea, MultiPageContentPane, MultiPageNavPane, Title, Titlebar }
   from 'src/sites/kits/UI'
import NotFound from 'src/sites/kits/Utils/NotFound'
import { useInitiativeList, useMe, useMyDirectThreadList, useOrganization } from 'src/core/graphql/hooks'
import { withInitiativeHierarchyProvider } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

// This is so that the Group Messages and Messages inside an initiative page
// can work the same (the InitiativeThreadChat assumes that an InitiativeHierarchy
// context provider exists)
const WrappedInitiativeThreadChat = withInitiativeHierarchyProvider({
   slugParam: 'initiativeSlug'
})(InitiativeThreadChat)

const Messages = () => {
   const match = useRouteMatch()
   const { myNotificationCount, theme } = useGlobalState()
   const initialSizeIsMobile = window.innerWidth < breakpoints.b
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER

   const { myDirectThreadList } = useMyDirectThreadList()
   const { isLoggedIn, networkStatus } = useMe()
   const initialLoading = networkStatus <= 2

   const { organization } = useOrganization()
   const groupInitiativeTypeIds = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'group')
      .map(t => t.id)

   const { initiativeList } = useInitiativeList({
      levels: 'all',
      typeIds: groupInitiativeTypeIds,
      userRelationshipFilter: {
         userId: 'me',
         hasElementType: 'roleParticipant'
      }
   })

   let navPanePaddingLeft = '24px[d-f]'
   const navPanePaddingRight = `
      ${theme.tmpViewportPaddingA}[a]
      ${theme.tmpViewportPaddingB}[b]
      24px[c-f]
   `

   const contentPaneStyleProps = {
      paddingLeft: `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         24px[c-f]
      `,
      paddingRight: `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
         ${theme.tmpViewportPaddingD}[d]
         24px[e-f]
      `
   }

   if (!isHeaderGL) {
      contentPaneStyleProps.marginRight = `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
         30px[d]
      `
   }

   if (isHeaderGL) {
      navPanePaddingLeft = ''
      contentPaneStyleProps.paddingRight = `
         ${theme.tmpViewportPaddingA}[a]
         ${theme.tmpViewportPaddingB}[b]
         ${theme.tmpViewportPaddingC}[c]
         ${theme.tmpViewportPaddingD}[d]
      `

      contentPaneStyleProps.marginRight = `
         ${theme.tmpViewportPaddingE}[e]
         ${theme.tmpViewportPaddingF}[f]
      `
   }

   const buttonProps = {
      activeBackgroundColor: 'var(--listMenuButtonActiveBackgroundColor)',
      activeTextColor: 'var(--listMenuButtonActiveTextColor)',
      borderRadius: '4px',
      paddingHorizontal: '10px',
      textColor: 'var(--listMenuButtonTextColor)',
      textFontWeight: '500'
   }

   // TODO: This should probably go to some kind of Unauthorized component instead
   if (!initialLoading && !isLoggedIn) {
      return <NotFound />
   }

   if (initialLoading) {
      return null
   }

   return (
      <LayoutContainerFixedArea>
         <MultiPageNavPane>
            <Titlebar
               size="lg"
               paddingLeft={navPanePaddingLeft}
               paddingRight={navPanePaddingRight}
               borderStyle="none">
               <Title size="md">Messages</Title>
            </Titlebar>
            <View
               paddingLeft={isHeaderGL ? 0 : '16px[d-f]'}
               paddingRight="16px">
               <ListMenuButton
                  icon={(
                     <View
                        display="flex"
                        alignItems="center"
                        style={{ opacity: '0.5' }}>
                        <InboxGlyph16Icon
                           width="14px"
                           height="14px"
                           color="var(--listMenuButtonTextColor)"
                        />
                     </View>
                  )}
                  linkExact
                  linkTo={`${match.url}/inbox`}
                  name="Inbox"
                  showBadge={myNotificationCount > 0}
                  {...buttonProps}
               />
               {myDirectThreadList.items.map(thread => (
                  <ListMenuButton
                     key={thread.id}
                     icon={(
                        <View
                           flex="0 0 auto"
                           display="flex"
                           flexFlow="column"
                           height="14px"
                           width="14px"
                           justifyContent="center"
                           alignItems="center"
                           transition="300ms"
                           backgroundColor="#aaa"
                           borderRadius="3px">
                           <Text
                              size="0.8"
                              weight="bold"
                              color="#fff"
                              style={{ transition: '300ms' }}>
                              {thread.title.charAt(0)}
                           </Text>
                        </View>
                     )}
                     linkTo={`${match.url}/direct/${thread.recipientUserId}/${thread.id}`}
                     name={thread.title}
                     showBadge={thread.hasUnreadEntries}
                     {...buttonProps}
                  />
               ))}
               {initiativeList.items.length > 0 && (
                  <View marginTop="20px">
                     <View paddingVertical="10px">
                        <Text size="1.5" weight="medium" color="#aaa">
                           Groups
                        </Text>
                     </View>
                     {initiativeList.items.map(initiative => (
                        <ListMenuButton
                           key={initiative.id}
                           icon={(
                              <View
                                 flex="0 0 auto"
                                 display="flex"
                                 flexFlow="column"
                                 height="14px"
                                 width="14px"
                                 justifyContent="center"
                                 alignItems="center"
                                 transition="300ms"
                                 backgroundColor="#aaa"
                                 borderRadius="3px">
                                 <Text
                                    size="0.8"
                                    weight="bold"
                                    color="#fff"
                                    style={{ transition: '300ms' }}>
                                    {initiative.name.charAt(0)}
                                 </Text>
                              </View>
                           )}
                           linkTo={`${match.url}/group/${initiative.slug}/${initiative.thread.id}`}
                           name={initiative.name}
                           showBadge={initiative.thread.hasUnreadEntries}
                           {...buttonProps}
                        />
                     ))}
                  </View>
               )}
            </View>
         </MultiPageNavPane>
         <Switch>
            {!initialSizeIsMobile && (
               <Redirect exact from={match.url} to={`${match.url}/inbox`} />
            )}
            <Route
               path={[
                  `${match.path}/inbox`,
                  `${match.path}/direct/:threadId`,
                  `${match.path}/group/:threadId`
               ]}>
               <MultiPageContentPane>
                  <Switch>
                     <Route
                        path={`${match.path}/inbox`}
                        render={() => <Inbox {...contentPaneStyleProps} />}
                     />
                     <Route
                        path={`${match.path}/direct/:recipientUserId/:threadId`}
                        render={() => <UserThreadChat {...contentPaneStyleProps} />}
                     />
                     <Route
                        path={`${match.path}/group/:initiativeSlug/:threadId`}
                        render={() => <WrappedInitiativeThreadChat {...contentPaneStyleProps} />}
                     />
                  </Switch>
               </MultiPageContentPane>
            </Route>
         </Switch>
      </LayoutContainerFixedArea>
   )
}

export default Messages
