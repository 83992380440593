// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { LAYOUT_HEADER } from 'config/constants/ui'
import { useGlobalState } from 'src/sites/state'

const MultiPageContentPane = ({ children }) => {
   const { theme } = useGlobalState()

   // TODO: This is kind of dumb and should be done more intelligently
   // PR #810
   const isHeaderGL = theme.tmpGlobalLayout === LAYOUT_HEADER
   let contentOffset = `
      0[a-b]
      32vw[c]
      300px[d-f]
   `

   if (isHeaderGL) {
      contentOffset = `0[a-b]
         calc(32vw + ${theme.tmpViewportPaddingC})[c]
         calc(300px + ${theme.tmpViewportPaddingD})[d]
         calc(300px + ${theme.tmpViewportPaddingE})[e]
         calc(300px + ${theme.tmpViewportPaddingF})[f]`
   }

   return (
      <View
         position="absolute"
         top="0px"
         left={contentOffset}
         right="0px"
         bottom="0px"
         style={{ overflow: 'hidden' }}>
         {children}
      </View>
   )
}

MultiPageContentPane.propTypes = {
   children: PropTypes.node.isRequired
}

export default MultiPageContentPane
