// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useGlobalState } from 'src/sites/state'

const MultiPageNavPane = ({ children }) => {
   const { theme, setTheme } = useGlobalState()
   const isFixedWidthLayout = theme.layoutContentFillMode === 'fixed'
   const menuWidth = '100%[a-b] 32vw[c] 300px[d-f]'

   useEffect(() => {
      setTheme({ ...theme, showColumnView: true })

      return (() => {
         setTheme({ ...theme, showColumnView: false })
      })
   }, [])

   return (
      <View
         float="left"
         height="100%"
         width="100%[a-b]"
         backgroundColor="var(--bodyBackgroundColor)"
         paddingLeft={isFixedWidthLayout
            ? theme.tmpViewportPadding
            : `
               ${theme.tmpViewportPaddingA}[a]
               ${theme.tmpViewportPaddingB}[b]
               0px[c-f]
               `
         }>
         <View
            position="relative"
            float="left"
            width={menuWidth}
            height="100%"
            backgroundColor="var(--bodyBackgroundColor)"
            borderRight="1px solid var(--columnLineColor)"
            scroll="on">
            {children}
         </View>
      </View>
   )
}

MultiPageNavPane.propTypes = {
   children: PropTypes.node.isRequired
}

export default MultiPageNavPane
