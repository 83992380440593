// =========================================================================================@@
// Last Updated Date: Mar 29, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, NotificationManagerContext, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { initiativeEventsUrl, initiativePeopleUrl, initiativeScheduleUrl }
   from 'config/constants/urls'
import { useCreateInitiative, useOrganizationWithOptions, useUpdateInitiative,
   useUpdateOrganization } from 'src/core/graphql/hooks'
import PageCreateOption from 'src/sites/kits/Object/components/PageCreateOption'
import { Title } from 'src/sites/kits/UI'
import { Input } from 'src/sites/kits/Utils/ConnectedForm'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'

const appPageRedirects = {
   appsPeopleEnabled: initiative => initiativePeopleUrl(initiative),
   appsEventsEnabled: initiative => initiativeEventsUrl(initiative),
   appsScheduleEnabled: initiative => initiativeScheduleUrl(initiative)
}

const PageCreate = ({
   onCancelButtonClick,
   onCreate,
   paddingLeft,
   paddingRight
}) => {
   const history = useHistory()
   const [pageTypeData, setPageTypeData] = useState({
      pageFormat: 'custom',
      dynamicPageTypeId: null
   })

   const { showNotification } = useContext(NotificationManagerContext)
   const { theme } = useGlobalState()

   const { initiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { createInitiative } = useCreateInitiative()
   const { updateOrganization } = useUpdateOrganization()
   const { organization, loading } = useOrganizationWithOptions({
      withGlobalNavLinksTargetMetadata: true
   })

   const highlightColor = organization?.theme?.highlightColor
   const enabledInitiativeTypes = organization?.initiativeTypes.filter(t => t.enabled &&
      !['collection', 'media', 'note', 'page', 'resource', 'session'].includes(t.class)) || []

   // NOTE: For now, until we have more confidence, we only allow users to create
   // 1 page in the Global Nav (Main Menu) per initiative type

   // Initiative Type Ids of Dynamic Pages currently in Global Nav
   const globalNavDynamicPageTypeIds = organization?.globalNavLinks
      .map(i => i.initiative?.dynamicPageType?.id)

   const availableDynamicPageTypes = enabledInitiativeTypes
      .filter(t => !globalNavDynamicPageTypeIds.includes(t.id))

   const isRootPage = !initiative
   const isSession = initiative?.class === 'session'
   const primaryRole = initiative?.roles.find(r => r.type === 'primary')

   const handleEnableApp = async (field) => {
      try {
         const newEnabled = !initiative[field]
         await updateInitiative({ id: initiative.id }, { [field]: newEnabled })
         showNotification({
            message: 'Changes saved successfully!',
            title: 'Success!',
            type: 'success'
         })

         history.push(appPageRedirects[field](initiative))
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      }
   }

   const handleCreatePage = async (values, actions) => {
      try {
         const payload = {
            ...values,
            pageFormat: pageTypeData.pageFormat,
            privacy: 'admin',
            typeId: organization.initiativeTypes.find(t => t.class === 'page').id
         }

         if (pageTypeData.dynamicPageTypeId) {
            payload.dynamicPageTypeId = pageTypeData.dynamicPageTypeId
         }

         if (!isRootPage) {
            payload.parentInitiativeId = initiative.id

            // If parent initiative of new page is admin or draft,
            // new page should be inherit
            if (['admin', 'draft'].includes(initiative.privacy)) {
               payload.privacy = 'inherit'
            }
         }

         const result = await createInitiative(payload)

         // If Root (Main Menu) page, we manually create a corresponding globalNavLink
         if (isRootPage) {
            await updateOrganization({
               globalNavLinks: [{
                  operation: 'createInternalLink',
                  createInternalLink: {
                     name: result.data.createInitiative.name,
                     initiativeId: result.data.createInitiative.id
                  }
               }]
            })
         }

         if (onCreate) {
            onCreate(result.data.createInitiative)
         }

         showNotification({
            message: 'New page created successfully',
            title: 'Success!',
            type: 'success'
         })
      } catch (err) {
         showNotification({
            message: err.message,
            title: 'Error',
            type: 'error'
         })
      } finally {
         actions.setSubmitting(false)
      }
   }

   useEffect(() => {
      if (initiative?.type.class === 'session') {
         setPageTypeData({
            pageFormat: 'media',
            dynamicPageTypeId: null
         })
      }
   }, [initiative?.type.class])

   if (loading) {
      return null
   }

   return (
      <View
         width="100%"
         display="flex"
         justifyContent="center"
         marginTop={isRootPage && theme.viewportMarginTop}
         paddingVertical={isRootPage ? '90px' : '40px'}
         paddingLeft={paddingLeft}
         paddingRight={paddingRight}>
         <View width="100%" maxWidth={isRootPage && '600px'}>
            <Formik
               initialValues={{ name: '' }}
               onSubmit={handleCreatePage}
               render={({ handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form
                     elementAppearance="plain"
                     elementBackgroundColor="#eee"
                     onSubmit={handleSubmit}>
                     <View
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginBottom="40px">
                        <Title size={isRootPage ? 'lg' : 'md'}>
                           New Page
                        </Title>
                        <View display="flex" style={{ gap: '4px' }}>
                           <Button
                              onClick={onCancelButtonClick}
                              color="#eee"
                              name="Cancel"
                              textColor="#333"
                           />
                           <Button
                              type="submit"
                              color={highlightColor}
                              mode={isSubmitting ? 'loading' : 'normal'}
                              name="Create"
                           />
                        </View>
                     </View>
                     <View margin="20px 0px">
                        <Text size={theme.tmpBodyTextSize} color="#333" weight="medium">
                           {'Page Name'}
                        </Text>
                     </View>
                     <Input name="name" size="lg" placeholder="Enter page name..." />
                     <View margin="20px 0px">
                        <Text size={theme.tmpBodyTextSize} color="#333" weight="medium">
                           Choose a page type:
                        </Text>
                     </View>
                     {!isSession && (
                        <PageCreateOption
                           highlightColor={highlightColor}
                           iconName="document"
                           name="Blank"
                           onClick={() => {
                              setPageTypeData({ pageFormat: 'custom', dynamicPageTypeId: null })
                           }}
                           selected={pageTypeData.pageFormat === 'custom'}
                           textSize={theme.tmpBodyTextSize}
                        />
                     )}
                     <PageCreateOption
                        highlightColor={highlightColor}
                        iconName="folder"
                        name="Files & Links"
                        onClick={() => {
                           setPageTypeData({ pageFormat: 'media', dynamicPageTypeId: null })
                        }}
                        selected={pageTypeData.pageFormat === 'media'}
                        textSize={theme.tmpBodyTextSize}
                     />
                     {isRootPage && (
                        <>
                           {availableDynamicPageTypes.map(type => (
                              <PageCreateOption
                                 key={type.id}
                                 highlightColor={highlightColor}
                                 iconName={type.class}
                                 name={type.namePlural}
                                 onClick={() => {
                                    setPageTypeData({
                                       pageFormat: 'dynamic',
                                       dynamicPageTypeId: type.id
                                    })
                                 }}
                                 selected={pageTypeData.pageFormat === 'dynamic' && pageTypeData.dynamicPageTypeId === type.id}
                                 textSize={theme.tmpBodyTextSize}
                              />
                           ))}
                        </>
                     )}
                     {!isRootPage && (
                        <>
                           {!initiative.appsPeopleEnabled && (
                              <PageCreateOption
                                 highlightColor={highlightColor}
                                 iconName="group"
                                 name={primaryRole?.name}
                                 textSize={theme.tmpBodyTextSize}
                                 onClick={() => handleEnableApp('appsPeopleEnabled')}
                              />
                           )}
                           {!initiative.appsEventsEnabled && initiative.class === 'group' && (
                              <PageCreateOption
                                 highlightColor={highlightColor}
                                 iconName="event"
                                 name="Events"
                                 textSize={theme.tmpBodyTextSize}
                                 onClick={() => handleEnableApp('appsEventsEnabled')}
                              />
                           )}
                           {!initiative.appsScheduleEnabled && initiative.class === 'event' && (
                              <PageCreateOption
                                 highlightColor={highlightColor}
                                 iconName="event"
                                 name="Schedule"
                                 textSize={theme.tmpBodyTextSize}
                                 onClick={() => handleEnableApp('appsScheduleEnabled')}
                              />
                           )}
                        </>
                     )}
                  </Form>
               )}
            />
         </View>
      </View>
   )
}

PageCreate.propTypes = {
   onCancelButtonClick: PropTypes.func.isRequired,
   onCreate: PropTypes.func.isRequired,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

PageCreate.defaultProps = {
   paddingLeft: '20px',
   paddingRight: '20px'
}

export default PageCreate
