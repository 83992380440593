// =========================================================================================@@
// Last Updated Date: Mar 31, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import { Spinner, View } from 'oio-react'
import { useRouteMatch } from 'react-router-dom'
import Note from 'src/core/kits/Medium/apps/Note'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import { InitiativeHierarchyContext, withInitiativeHierarchyProvider }
   from 'src/sites/kits/Utils/InitiativeHierarchy'

const MediumNote = () => {
   const match = useRouteMatch()
   const { initiative, initiativeLoading } = useContext(InitiativeHierarchyContext)
   if (initiativeLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            textAlign="center"
            padding="90px"
            borderRadius="6px">
            <Spinner color="var(--bodyTextColor)" width="24px" height="24px" />
         </View>
      )
   }

   return (
      <>
         <View
            position="fixed"
            top="0px"
            right="0px"
            height="var(--titlebarHeight)"
            display="flex"
            alignItems="center"
            padding="0px 24px"
            zIndex="2">
            <ObjectMoreButton
               buttonSize="var(--baseComponentHeight-sm)"
               color="transparent"
               editUrl={`${match.url}/-/edit`}
               popoverAnchorOriginVertical="top"
            />
         </View>
         <Note
            body={initiative.body}
            bodyVersion={initiative.body?.v}
            dateLastUpdated={initiative.dateLastUpdated}
            id={initiative.id}
            name={initiative.name}
            // onDelete={onDelete}
         />
      </>
   )
}

export default withInitiativeHierarchyProvider({ slugParam: 'pageSlug' })(MediumNote)
