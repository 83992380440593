// =========================================================================================@@
// Last Updated Date: Feb 18, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const ListMenuButton = ({
   active,
   borderRadius,
   borderStyle,
   icon,
   linkExact,
   linkIsExternal,
   linkTo,
   name,
   onClick,
   onFocus,
   onMouseOver,
   paddingHorizontal,
   pinned,
   rightDetail,
   showBadge,
   showPlaceholderIcon
}) => {
   let ButtonComponent = 'div'
   let buttonProps = {}

   if (linkTo) {
      ButtonComponent = linkIsExternal ? 'a' : NavLink
      buttonProps = linkIsExternal
         ? { href: linkTo, target: '_blank', rel: 'noopener noreferrer' }
         : { to: linkTo, exact: linkExact, activeClassName: 'active' }
   }

   if (!linkIsExternal && typeof active === 'boolean') {
      buttonProps.isActive = () => active
   }

   return (
      <ButtonComponent
         onClick={onClick}
         onFocus={onFocus}
         onMouseOver={onMouseOver}
         className={`ui-listmenubutton ${active ? 'active' : ''}`}
         {...buttonProps}>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingHorizontal={paddingHorizontal}
            width="100%"
            height="var(--baseComponentHeight-md)">
            <View
               className="line"
               position="absolute"
               borderTop={borderStyle}
               bottom="0px"
               left="0px"
               right={borderRadius}
            />
            <View
               display="flex"
               justifyContent="flex-start"
               alignItems="center"
               width={showBadge ? '85%' : '95%'}>
               {icon && (
                  <View
                     display="flex"
                     alignItems="center"
                     flex="0 0 auto"
                     paddingRight="15px">
                     {icon}
                  </View>
               )}
               {/* TODO: For testing */}
               {/* <View
                  display="flex"
                  alignItems="center"
                  flex="0 0 auto"
                  paddingRight="15px">
                  #
               </View> */}
               {showPlaceholderIcon && (
                  <View
                     display="flex"
                     alignItems="center"
                     flex="0 0 auto"
                     width="16px"
                     height="16px"
                     border="1px solid var(--listMenuButtonTextColor)"
                     borderRadius="4px"
                     marginRight="15px"
                     opacity="0.2"
                  />
               )}
               <div
                  style={{
                     color: 'inherit',
                     width: '100%',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                  }}>
                  {name}
                  {pinned && '(Pinned)'}
               </div>
            </View>
            {rightDetail}
            {showBadge && (
               <View
                  margin="2px 10px 0px 10px"
                  width="6px"
                  height="6px"
                  backgroundColor="orange"
                  borderRadius="50%"
               />
            )}
         </View>
      </ButtonComponent>
   )
}

ListMenuButton.propTypes = {
   active: PropTypes.bool,
   borderStyle: PropTypes.string,
   icon: PropTypes.node,
   linkExact: PropTypes.bool,
   linkIsExternal: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   paddingHorizontal: PropTypes.string,
   pinned: PropTypes.bool,
   rightDetail: PropTypes.node,
   showBadge: PropTypes.bool,
   showPlaceholderIcon: PropTypes.bool
}

ListMenuButton.defaultProps = {
   active: undefined,
   borderStyle: undefined,
   icon: undefined,
   linkExact: false,
   linkIsExternal: false,
   linkTo: undefined,
   onClick: undefined,
   paddingHorizontal: '30px',
   pinned: false,
   rightDetail: undefined,
   showBadge: false,
   showPlaceholderIcon: false
}

export default ListMenuButton
