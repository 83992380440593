// =========================================================================================@@
// Last Updated Date: Mar 28, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext, useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Modal, View } from 'oio-react'
import PropTypes from 'prop-types'
import { toast } from 'sonner'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ArrowDownIcon } from 'assets/icons'
import uiConstants from 'config/constants/ui'
import { initiativeSettingsPeopleUrl, initiativeSettingsReportsUrl } from 'config/constants/urls'
import ListMenuButton from 'src/sites/kits/Global/components/ListMenuButton'
import ObjectDeleteConfirm from 'src/sites/kits/Object/components/DeleteConfirm'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useCreateReport, useUpdateInitiative } from 'src/core/graphql/hooks'

const buttonProps = {
   paddingHorizontal: '8px'
}

const initiativeAppEnabledFieldNames = {
   events: 'appsEventsEnabled',
   people: 'appsPeopleEnabled',
   schedule: 'appsScheduleEnabled'
}

const initiativeAppPublishedFieldNames = {
   events: 'appsEventsPublished',
   schedule: 'appsSchedulePublished'
}

const NestedPageOptionsControl = ({ editUrl, pageId, pageName, pagePublished, pageType }) => {
   const history = useHistory()
   const match = useRouteMatch()
   const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
   const [popoverOpen, setPopoverOpen] = useState(false)
   const { initiative, refetchInitiative } = useContext(InitiativeHierarchyContext)
   const { updateInitiative } = useUpdateInitiative()
   const { createReport } = useCreateReport()
   const reportsUrl = initiativeSettingsReportsUrl(initiative)
   let eventStartDate

   if (initiative.class === 'event' && initiative.startDateTimeISO8601) {
      const dateObj = new Date(initiative.startDateTimeISO8601)
      // eslint-disable-next-line prefer-destructuring
      eventStartDate = dateObj.toISOString().split('T')[0]
   }

   const appEnabledFieldName = initiativeAppEnabledFieldNames[pageType]
   const appPublishedFieldName = initiativeAppPublishedFieldNames[pageType]

   const handleDisable = async () => {
      const appIsEnabled = initiative[appEnabledFieldName]
      await updateInitiative({ id: initiative.id }, {
         [appEnabledFieldName]: !appIsEnabled
      })

      history.push(match.url)
   }

   const handleTogglePublished = async () => {
      const appIsPublished = initiative[appPublishedFieldName]
      await updateInitiative({ id: initiative.id }, {
         [appPublishedFieldName]: !appIsPublished
      })

      refetchInitiative()
   }

   const handleUpdateInitiative = async (value) => {
      try {
         await updateInitiative({ id: pageId }, value)

         toast.success('Page updated')
         refetchInitiative()
      } catch (err) {
         toast.error(err.message)
      }
   }

   const handleGenerateScheduleReport = () => {
      createReport({
         initiativeId: initiative.id,
         type: 'EventSchedule'
      })

      history.push(reportsUrl)
   }

   if (!initiative.currentUserCanEdit) {
      return null
   }

   return (
      <>
         <DropdownMenu.Root
            open={popoverOpen}
            onOpenChange={open => setPopoverOpen(open)}>
            <DropdownMenu.Trigger>
               <View
                  display="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  width="10px"
                  height="16px"
                  borderRadius="5px"
                  marginLeft="6px"
                  onClick={(e) => {
                     e.preventDefault()
                     e.stopPropagation()
                  }}>
                  <ArrowDownIcon
                     width="10px"
                     height="10px"
                     strokeWidth="2px"
                     color="var(--listMenuButtonActiveTextColor)"
                  />
               </View>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
               <DropdownMenu.Content
                  className="ui-popover"
                  sideOffset={16}
                  collisionPadding={16}>
                  <View onClick={() => setPopoverOpen(false)} width="200px">
                     {pageType === 'home' && (
                        <ListMenuButton
                           linkTo={editUrl}
                           name={`Edit ${initiative.type.nameSingular} information`}
                           {...buttonProps}
                        />
                     )}
                     {pageType === 'custom' && (
                        <>
                           <ListMenuButton
                              linkTo={editUrl}
                              name="Edit this Page"
                              {...buttonProps}
                           />
                           <ListMenuButton
                              onClick={() => {
                                 handleUpdateInitiative({
                                    privacy: pagePublished ? 'admin' : 'inherit'
                                 })
                              }}
                              name={pagePublished ? 'Unpublish this Page' : 'Publish this Page'}
                              {...buttonProps}
                           />
                           <DropdownMenu.Separator className="ui-popover-seperator" />
                           <ListMenuButton
                              onClick={() => setDeleteModalIsOpen(true)}
                              name="Delete Page"
                              {...buttonProps}
                           />
                        </>
                     )}
                     {pageType === 'schedule' && (
                        <>
                           <ListMenuButton
                              linkTo={`${match.url}/-/schedule/${eventStartDate}/create`}
                              name="Add Session to Schedule"
                              {...buttonProps}
                           />
                           <ListMenuButton
                              onClick={(e) => {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 handleGenerateScheduleReport()
                              }}
                              name="Generate Schedule Report"
                              {...buttonProps}
                           />
                        </>
                     )}
                     {['schedule'].includes(pageType) && (
                        <ListMenuButton
                           onClick={handleTogglePublished}
                           name={initiative[appPublishedFieldName]
                              ? 'Unpublish Page'
                              : 'Publish Page'
                           }
                           {...buttonProps}
                        />
                     )}
                     {pageType === 'people' && initiative.roles.map(role => (
                        <ListMenuButton
                           key={role.id}
                           linkTo={`${initiativeSettingsPeopleUrl(initiative, role.id)}/invited/new`}
                           name={`Invite new ${role.name}`}
                           {...buttonProps}
                        />
                     ))}
                     {['people'].includes(pageType) && (
                        <DropdownMenu.Separator className="ui-popover-seperator" />
                     )}
                     {['events', 'people', 'schedule'].includes(pageType) && (
                        <ListMenuButton
                           onClick={handleDisable}
                           name="Remove Page"
                           {...buttonProps}
                        />
                     )}
                  </View>
               </DropdownMenu.Content>
            </DropdownMenu.Portal>
         </DropdownMenu.Root>
         {pageId && (
            <Modal
               borderRadius="6px"
               width="90%[a-b] 300px[c-f]"
               onCloseTrigger={() => setDeleteModalIsOpen(false)}
               open={deleteModalIsOpen}
               zIndex={uiConstants.zIndexes.deleteConfirmModal}>
               <ObjectDeleteConfirm
                  activeSubInitiativeTypeNames={[]}
                  initiativeId={pageId}
                  initiativeName={pageName}
                  onCancelButtonClick={() => setDeleteModalIsOpen(false)}
                  onDelete={() => history.push(match.url)}
               />
            </Modal>
         )}
      </>
   )
}

NestedPageOptionsControl.propTypes = {
   editUrl: PropTypes.string,
   pageId: PropTypes.string,
   pageName: PropTypes.string.isRequired,
   pagePublished: PropTypes.bool,
   pageType: PropTypes.oneOf(['custom', 'events', 'home', 'people', 'schedule']).isRequired
}

NestedPageOptionsControl.defaultProps = {
   editUrl: undefined,
   pageId: undefined,
   pagePublished: undefined
}

export default NestedPageOptionsControl
