// =========================================================================================@@
// Last Updated Date: Mar 21, 2022
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useInitiative, useOrganization } from 'src/core/graphql/hooks'
import GNS3ResourceView from 'src/sites/custom/gns3/Resource/View'
import Collection from 'src/sites/kits/Object/apps/Collection' // eslint-disable-line import/no-cycle
import CollectionMediums from 'src/sites/kits/Object/apps/CollectionMediums'
import CollectionSidebar from 'src/sites/kits/Object/apps/CollectionSidebar'
import CompiledPage from 'src/sites/kits/Object/apps/CompiledPage'
import CustomPage from 'src/sites/kits/Object/apps/CustomPage'
import DynamicViewPage from 'src/sites/kits/Object/apps/DynamicViewPage'
import Group from 'src/sites/kits/Object/apps/Group'
import MediaPage from 'src/sites/kits/Object/apps/MediaPage'
import { LayoutContainer, LayoutContainerInner } from 'src/sites/kits/UI'

const pageComponents = {
   compiled: CompiledPage,
   custom: CustomPage,
   dynamic: Collection,
   media: CustomPage
}

const ObjectView = () => {
   const { initiativeSlug } = useParams()
   const { organization } = useOrganization()
   const { initiative, networkStatus } = useInitiative({ slug: initiativeSlug })
   const initialLoading = networkStatus <= 2

   // Note: This is currently only for GNS3
   const isUrlOrFilesResource = initiative?.class === 'resource' &&
      ['url', 'files'].includes(initiative?.resourceFormat)

   // TODO: Temp Fix - not sure why this is necessary
   // Right now, when navigating between pages, the scroll position
   // is always maintained. This is very confusing for users
   // especially on mobile - SY
   useEffect(() => {
      setTimeout(() => window.scrollTo({ top: 0 }), 0)
   }, [initiative?.id])

   if (initialLoading) {
      return null
   }

   if (isUrlOrFilesResource) {
      return <GNS3ResourceView />
   }

   if (initiative.class === 'page' && initiative.pageFormat === 'media') {
      return <MediaPage />
   }

   if (initiative.class === 'medium' && initiative.protoFormat === 'sidebar') {
      return <CollectionMediums />
   }

   // TODO: Group pages still need: Join Button, privacy, show members somehow
   if (['group', 'medium'].includes(initiative.class) && organization.slug === 'mother-co') {
      return <Group />
   }

   // Formats: custom, dynamic, media
   if (initiative.protoFormat === 'custom' || initiative.class === 'page') {
      const PageComponent = pageComponents[initiative.pageFormat] || CustomPage
      return <PageComponent />
   }

   if (initiative.protoFormat === 'sidebar') {
      return <CollectionSidebar />
   }

   return (
      <LayoutContainer backgroundColor="#fff">
         <LayoutContainerInner>
            <DynamicViewPage />
         </LayoutContainerInner>
      </LayoutContainer>
   )
}

export default ObjectView
