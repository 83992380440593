// =========================================================================================@@
// Last Updated Date: Apr 21, 2023
// Last Updated By: Steven
// Status Level: 1
// ===========================================================================================

import React, { Fragment, useMemo } from 'react'
import { Carousel, Spinner, View } from 'oio-react'
import { Link } from 'react-router-dom'
import { useActivityFeed, useInitiativeList, useOrganization } from 'src/core/graphql/hooks'
import ObjectBlogCard from 'src/sites/kits/Object/components/ListItem/BlogCard'
import { Title } from 'src/sites/kits/UI'
import MoreButton from './MoreButton'

const formatTime = (date) => {
   return (new Date(date)).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
}

const Latest = () => {
   const { activityFeed, networkStatus } = useActivityFeed()
   const { organization } = useOrganization()
   const initialLoading = networkStatus <= 2

   const featuredInitiativeTypes = useMemo(() => {
      return organization.initiativeTypes
         .filter(type => type.enabled && ['event', 'group', 'post'].includes(type.class))
   }, [organization.initiativeTypes])

   const featuredInitiativeTypeIds = featuredInitiativeTypes.map(type => type.id)
   const { initiativeList } = useInitiativeList({
      archived: 'exclude',
      levels: 'all',
      limit: 1,
      sortBy: 'dateLastUpdated',
      typeIds: featuredInitiativeTypeIds
   })

   return (
      <View
         position="relative"
         width="100%"
         paddingBottom="120px">
         <View
            position="sticky"
            top="0px"
            left="0px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="64px[a-c] var(--titlebarHeight)[d-f]"
            className="ui-titlebar"
            paddingHorizontal="var(--gatherAppPaddingHorizontal)"
            borderBottom="1px solid var(--primaryLineColor)"
            width="100%">
            <Title size="md">
               Latest
            </Title>
            <MoreButton />
         </View>
         <View
            display="none"
            width="100%"
            backgroundColor="var(--inputBackgroundColor)[c-f]"
            borderBottom="1px solid var(--primaryLineColor)[c-f]"
            padding="0px var(--gatherAppPaddingHorizontal)">
            <Carousel
               buttonOffsetFromSides="5px"
               buttonIconColor="#fff"
               buttonBackgroundColor="#aaa"
               buttonIconSize="12px"
               buttonSize="32px"
               numSlidesPerPane="1"
               spacing={40}>
               {initiativeList.items.map(initiative => (
                  <ObjectBlogCard
                     key={initiative.id}
                     borderStyle="none"
                     coverImageSpacing="24px"
                     coverImageWidth="64px[a] 72px[b-f]"
                     coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                     date={initiative.startDate}
                     dateLastUpdated={initiative.dateLastUpdated}
                     iconName={initiative.class}
                     linkTo={initiative.gatherUrl}
                     name={initiative.name}
                     location={initiative.location}
                     privacy={initiative.privacy}
                     subtitle={initiative.startDate || initiative.dateLastUpdated}
                     subtitleMaxLines={1}
                     summary={initiative.subtitle || initiative.body.summary}
                     summaryMaxLines={1}
                     titleSize="sm"
                     textSpacing="2px"
                  />
               ))}
            </Carousel>
         </View>
         <View
            display="flex"
            justifyContent="center">
            <View width="100%">
               {initialLoading && (
                  <View
                     display="flex"
                     justifyContent="center"
                     padding="40px">
                     <Spinner width="20px" height="20px" color="#aaa" />
                  </View>
               )}
               {activityFeed.items.map((activityGroup, i) => (
                  <View
                     key={activityGroup.key}
                     width="100%">
                     <View
                        flex="1 1 auto"
                        style={{ color: 'var(--secondaryTextColor)' }}>
                        <View
                           wdith="100%"
                           display="flex"
                           flexFlow="row"
                           paddingRight="var(--gatherAppPaddingHorizontal)"
                           paddingLeft="var(--gatherAppPaddingHorizontal)[a-b]"
                           borderTop={i === 0 ? 'none' : '1px solid var(--primaryLineColor)'}
                           padding="12px 0px">
                           <View
                              flex="1 1 auto"
                              width="calc(100% - 100px)"
                              style={{ wordWrap: 'break-word' }}>
                              {activityGroup.key.includes('type:initiative.create') && (
                                 <span>
                                    {activityGroup.activities[0].targetParent && (
                                       <>
                                          <Link
                                             className="ui-link"
                                             to={`/-/profile/${activityGroup.activities[0].actor.id}`}>
                                             {activityGroup.activities[0].actor.fullName}
                                          </Link>
                                          {activityGroup.activities.length > 1 && (
                                             ` added ${activityGroup.activities.length} ${activityGroup.activities[0].target.type.namePlural}`
                                          )}
                                          {activityGroup.activities.length === 1 && (
                                             <>
                                                <span style={{ textTransform: 'lowercase' }}>
                                                   {` added the ${activityGroup.activities[0].target.nameSingular} `}
                                                </span>
                                                <Link
                                                   className="ui-link"
                                                   to={`/-/initiatives/${activityGroup.activities[0].target.slug}`}>
                                                   {activityGroup.activities[0].target.name}
                                                </Link>
                                             </>
                                          )}
                                          {' to '}
                                          <Link
                                             className="ui-link"
                                             to={`/-/initiatives/${activityGroup.activities[0].targetParent.slug}`}>
                                             {activityGroup.activities[0].targetParent.name}
                                          </Link>
                                       </>
                                    )}
                                    {!activityGroup.activities[0].targetParent && (
                                       <Fragment>
                                          <Link
                                             className="ui-link"
                                             to={`/-/profile/${activityGroup.activities[0].actor.id}`}>
                                             {activityGroup.activities[0].actor.fullName}
                                          </Link>
                                          {' created '}
                                          <Link
                                             className="ui-link"
                                             to={`/-/initiatives/${activityGroup.activities[0].target.slug}`}>
                                             {activityGroup.activities[0].target.name}
                                          </Link>
                                       </Fragment>
                                    )}
                                 </span>
                              )}
                              {activityGroup.key.includes('type:initiative.update') && (
                                 <Fragment>
                                    <Link
                                       className="ui-link"
                                       to={`/-/profile/${activityGroup.activities[0].actor.id}`}>
                                       {activityGroup.activities[0].actor.fullName}
                                    </Link>
                                    <span style={{ textTransform: 'lowercase' }}>
                                       {` updated the ${activityGroup.activities[0].target.type.nameSingular} `}
                                    </span>
                                    <Link
                                       className="ui-link"
                                       to={`/-/initiatives/${activityGroup.activities[0].target.slug}`}>
                                       {activityGroup.activities[0].target.name}
                                    </Link>
                                    {activityGroup.activities[0].targetParent && (
                                       <>
                                          {' in '}
                                          <Link
                                             className="ui-link"
                                             to={`/-/initiatives/${activityGroup.activities[0].targetParent.slug}`}>
                                             {activityGroup.activities[0].targetParent.name}
                                          </Link>
                                       </>
                                    )}
                                 </Fragment>
                              )}
                              {activityGroup.key.includes('type:message') && (
                                 <span>
                                    {activityGroup.activities.length}
                                    {' messages have been posted to '}
                                    {/*
                                    {`the ${activityGroup.activities[0].target.type.nameSingular} `}
                                    */}
                                    <Link
                                       className="ui-link"
                                       to={`/-/initiatives/${activityGroup.activities[0].target.slug}`}>
                                       {activityGroup.activities[0].target.name}
                                    </Link>
                                 </span>
                              )}
                           </View>
                           <View
                              flex="0 0 auto"
                              width="100px"
                              className="ui-meta-text"
                              textAlign="right">
                              {formatTime(activityGroup.date)}
                           </View>
                        </View>
                     </View>
                  </View>
               ))}
            </View>
         </View>
      </View>
   )
}

export default Latest
