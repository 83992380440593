// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useState } from 'react'
import fetch from 'cross-fetch'
import { Button, Modal, Spacer, Spinner, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { CloseIcon } from 'assets/icons'
import ResourceSettings from 'src/sites/custom/gns3/Resource/Settings'
import GNS3GithubApplianceBlock from 'src/sites/custom/gns3/Resource/GNS3GithubApplianceBlock'
import PreviewImageBlock from 'src/sites/custom/gns3/Resource/PreviewImageBlock'
import MessageFeed from 'src/sites/kits/Messages/components/Feed'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import ObjectTypeLabel from 'src/sites/kits/Object/components/TypeLabel'
import MetadataBlock from 'src/sites/kits/Object/components/ViewAside/MetadataBlock'
import UserListItemDetailsCard from 'src/sites/kits/People/components/UserListItemDetailsCard'
import { downloadFileAsset } from 'src/sites/kits/Utils'
import RichText from 'src/sites/kits/Utils/RichText'
import { useFileList, useInitiative, useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyProvider } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'
import { Title } from 'src/sites/kits/UI'

// TODO: This is a stupid GNS3 Hack, but not sure how this should actually work yet.
// See #561
const getGNS3ApplianceData = rawUrl => `/gns3/appliance/data?url=${encodeURIComponent(rawUrl)}`
const getGNS3ApplianceUrl = rawUrl => `/gns3/appliance/download?url=${encodeURIComponent(rawUrl)}`

const ResourceView = ({ displayMode, onCloseButtonClick }) => {
   const isFullscreen = displayMode === 'fullscreen'
   const history = useHistory()
   const { initiativeSlug } = useParams()
   const match = useRouteMatch()
   const [modalIsOpen, setModalIsOpen] = useState(true)
   const [applianceGithubData, setApplianceGithubData] = useState()

   const { organization } = useOrganization()
   const { theme } = useGlobalState()
   const { initiative, networkStatus, refetch } = useInitiative({ slug: initiativeSlug })

   // Resource Primary File
   const { fileList } = useFileList(
      { initiativeId: initiative?.id },
      { skip: !initiative?.id }
   )

   // TODO: This is a stupid GNS3 Hack, but not sure how this should actually work yet.
   // See #561
   const isGNS3Appliance = organization.slug === 'gns3' && initiative?.type.slug === 'appliances'

   useEffect(() => {
      if (isGNS3Appliance && initiative.url) {
         fetch(getGNS3ApplianceData(initiative.url), { method: 'get' }).then((response) => {
            if (response.ok) {
               response.json().then((result) => {
                  setApplianceGithubData(result)
               })
            }
         })
      }
   }, [isGNS3Appliance])

   // We don't want to display our "loading" state if the initiative is being refetched
   const initialLoading = networkStatus <= 2
   if (initialLoading) {
      return (
         <View
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="60px"
            width="100%"
            minHeight="80vh"
            backgroundColor="#fff"
            borderRadius="5px"
            style={{ overflow: 'hidden' }}>
            <Spinner width="30px" height="30px" color="#aaa" />
         </View>
      )
   }

   const coverUrl = initiative.coverMedia?.file.thumbnailUrlW1600
   const iconUrl = initiative.logoIcon?.file.thumbnailUrlW120
   const initiativeBody = initiative.body.elements[0].body
   const hasPreviewImages = initiative.body.elements?.[1]?.fileInstances.length > 0
   const previewImageFiles = initiative.body.elements?.[1]?.fileInstances.map(i => i.file)
   const hasTags = initiative.tags.length > 0

   return (
      <InitiativeHierarchyProvider
         initiative={initiative}
         refetchInitiative={refetch}>
         <Helmet title={`${initiative.name} | ${organization.name}`} />
         <View
            marginTop={isFullscreen && theme.viewportMarginTop}
            position="relative"
            width="100%"
            height="100%"
            backgroundColor="#fff"
            borderRadius="5px"
            scroll="on"
            style={{ overflow: 'hidden' }}>
            <ObjectStatusBanner />
            {!isFullscreen && (
               <View
                  position="absolute"
                  top="0px"
                  right="0px"
                  height="70px"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  padding="0px 20px"
                  zIndex="2">
                  <View onClick={onCloseButtonClick}>
                     <CloseIcon
                        width="30px"
                        height="30px"
                        strokeWidth="1px"
                        color="#888"
                     />
                  </View>
               </View>
            )}
            <View
               paddingLeft={isFullscreen && theme.viewportMarginLeft}
               paddingRight={isFullscreen && theme.viewportMarginRight}>
               <View
                  display="flex"
                  justifyContent="center"
                  width="100%">
                  <View
                     width="100%"
                     maxWidth="900px[a-e] 1200px[f]"
                     paddingHorizontal={isFullscreen && theme.viewportPaddingLeft}>
                     {coverUrl && (
                        <View float="left" width="100%" borderBottom="1px solid #eee">
                           <img
                              src={coverUrl}
                              alt={initiative.name}
                              width="100%"
                           />
                        </View>
                     )}
                     <View float="left" width="100%">
                        <View
                           display="flex"
                           width="100%"
                           paddingVertical="25px[a-b] 50px[c-f]"
                           paddingHorizontal={!isFullscreen && '25px[a-b] 50px[c-f]'}>
                           <View
                              marginTop="6px"
                              flex="0 0 auto"
                              width="70px[a-b] 90px[c-f]"
                              height="70px[a-b] 90px[c-f]"
                              backgroundColor="#eee"
                              backgroundImage={`url(${iconUrl})`}
                              backgroundSize="cover"
                              border="1px solid #ddd"
                              borderRadius="9px"
                              style={{ overflow: 'hidden' }}>
                              {!iconUrl && (
                                 <InitiativeDefaultThumbnail
                                    highlightColor={initiative.type.highlightColor}
                                    iconName={initiative.type.iconName}
                                 />
                              )}
                           </View>
                           <View flex="1 1 auto">
                              <View
                                 display="flex"
                                 justifyContent="space-between">
                                 <View flex="1 1 auto" padding="0px 0px 0px 30px">
                                    <ObjectTypeLabel />
                                    <Spacer size="1" />
                                    <Title size="xl">
                                       {initiative.name}
                                    </Title>
                                    {initiative.subtitle && (
                                       <>
                                          <Spacer size="2" />
                                          <Text color="#888" size="4">
                                             {initiative.subtitle}
                                          </Text>
                                       </>
                                    )}
                                    <Spacer size="2" />
                                    <Text
                                       color="#aaa"
                                       size="1.5">
                                       {'Posted by '}
                                       <Link to={initiative.addedBy.id} style={{ color: '#888' }}>
                                          {` ${initiative.addedBy.fullName} `}
                                       </Link>
                                       {` • ${initiative.dateAdded}`}
                                    </Text>
                                    <View
                                       display="flex"
                                       justifyContent="space-between"
                                       width="100%"
                                       borderTop="1px solid rgba(0,0,0,0.1)"
                                       marginTop="30px"
                                       paddingTop="30px">
                                       {initiative.resourceFormat === 'url' && (
                                          <a
                                             href={isGNS3Appliance
                                                ? getGNS3ApplianceUrl(initiative.url)
                                                : initiative.url}
                                             download={isGNS3Appliance}
                                             rel="noopener noreferrer"
                                             target="_blank">
                                             <Button
                                                name="Download"
                                                size="md"
                                                color={initiative.type.highlightColor || '#000'}
                                             />
                                          </a>
                                       )}
                                       {initiative.resourceFormat === 'files' && (
                                          <Button
                                             onClick={() => downloadFileAsset(fileList.items[0].id)}
                                             name="Download"
                                             size="md"
                                             color={initiative.type.highlightColor || '#000'}
                                          />
                                       )}
                                       <Spacer size="1" orientation="vertical" />
                                       <ObjectMoreButton />
                                    </View>
                                 </View>
                              </View>
                           </View>
                        </View>
                        <View
                           float="left"
                           width="100%"
                           paddingVertical="25px[a-b] 50px[c-f]"
                           paddingHorizontal={!isFullscreen && '25px[a-b] 50px[c-f]'}
                           backgroundColor={!isFullscreen && '#fafafa'}
                           borderTop="1px solid #eee">
                           <View
                              display="none[a-b] block[c-f]"
                              float="right"
                              width="130px"
                              paddingLeft="25px">
                              <MetadataBlock initiative={initiative} />
                           </View>
                           <View
                              float="left"
                              width="100%[a-b] calc(100% - 130px)[c-f]"
                              minHeight="50vh"
                              paddingRight="15px[c-f]"
                              paddingBottom="50px">
                              {/* eslint-disable max-len */}
                              {applianceGithubData && (
                                 <GNS3GithubApplianceBlock
                                    description={applianceGithubData.description}
                                    documentationUrl={applianceGithubData.documentation_url}
                                    images={applianceGithubData.images}
                                    iou={applianceGithubData.iou}
                                    name={applianceGithubData.name}
                                    qemu={applianceGithubData.qemu}
                                    usage={applianceGithubData.usage}
                                    versions={applianceGithubData.versions}
                                 />
                              )}
                              {/* eslint-enable max-len */}
                              {initiativeBody && (
                                 <View
                                    float="left"
                                    width="100%"
                                    paddingBottom="30px"
                                    minHeight="60px">
                                    <Text size="2.5" lineHeight="150%" color="#444">
                                       <RichText html={initiativeBody} />
                                    </Text>
                                 </View>
                              )}
                              {!initiativeBody && !applianceGithubData?.description && (
                                 <View
                                    float="left"
                                    width="100%"
                                    minHeight="60px">
                                    <Text size="2" lineHeight="150%" color="#888">
                                       {`This ${initiative.type.nameSingular} currently has no description`}
                                    </Text>
                                 </View>
                              )}
                              {hasPreviewImages && (
                                 <View
                                    float="left"
                                    width="100%"
                                    padding="20px 0px"
                                    borderTop="1px solid #eee"
                                    borderLeft22="1px solid #e5e5e5"
                                    borderRight22="1px solid #e5e5e5">
                                    <PreviewImageBlock
                                       files={previewImageFiles}
                                    />
                                 </View>
                              )}
                              {hasTags && (
                                 <View
                                    float="left"
                                    width="100%"
                                    paddingVertical="20px[a-d] 20px[e-f]"
                                    borderTop="1px solid #ececec">
                                    <View maxWidth="650px" marginTop="10px">
                                       {initiative.tags.map(tag => (
                                          <View
                                             key={tag}
                                             display="inline-flex"
                                             alignItems="center"
                                             height="25px"
                                             padding="0px 10px"
                                             borderRadius="2px"
                                             marginRight="10px"
                                             marginBottom="10px"
                                             backgroundColor="#eee">
                                             <Text size="2" color="#888">{tag}</Text>
                                          </View>
                                       ))}
                                    </View>
                                 </View>
                              )}
                              <View
                                 float="left"
                                 display="flex"
                                 justifyContent="space-between"
                                 alignItems="center"
                                 borderTop="1px solid #eee"
                                 paddingVertical="20px[a-b] 25px[c-f]"
                                 width="100%">
                                 <View flex="1 1 auto">
                                    <UserListItemDetailsCard
                                       id={initiative.addedBy.id}
                                       avatarMedia={initiative.addedBy.avatarMedia}
                                       fullName={initiative.addedBy.fullName}
                                       organization={initiative.addedBy.organization}
                                       position={initiative.addedBy.position}
                                    />
                                 </View>
                                 <View flex="0 0 auto">
                                    <Button
                                       linkTo={`/-/profile/${initiative.addedBy.id}`}
                                       width="120px"
                                       name="View Profile"
                                       size="sm"
                                       color="#eee"
                                       textColor="#333"
                                    />
                                 </View>
                              </View>
                              <View
                                 float="left"
                                 width="100%"
                                 borderTop="1px solid rgba(0,0,0,0.07)">
                                 <View width="100%" paddingVertical="20px">
                                    <Text size="0.8" weight="medium" transform="uppercase" color="#888">
                                       Comments
                                    </Text>
                                 </View>
                                 <MessageFeed
                                    currentUserCanCreate={
                                       initiative.appsMessagesCurrentUserCanCreate
                                    }
                                    currentUserCanModerate={
                                       initiative.currentUserCanModerateMessages
                                    }
                                    displayMode="conversation"
                                    hasUnreadEntries={initiative.thread.hasUnreadEntries}
                                    messageLastViewed={initiative.thread.messageLastViewed}
                                 />
                              </View>
                           </View>
                        </View>
                     </View>
                  </View>
               </View>
            </View>
         </View>
         {initiative.currentUserCanEdit && (
            <Route path={`${match.path}/-/settings`}>
               <Modal
                  borderRadius="3px"
                  overlayBackgroundColor="rgba(45,40,40,0.9)"
                  width="100%[a-b] 90%[c-d] 100%[e-f]"
                  height="100%"
                  maxWidth="1000px"
                  onCloseComplete={() => {
                     history.push(match.url)
                     setModalIsOpen(true)
                  }}
                  open={modalIsOpen}>
                  <ResourceSettings returnUrl={match.url} />
               </Modal>
            </Route>
         )}
      </InitiativeHierarchyProvider>
   )
}

ResourceView.propTypes = {
   displayMode: PropTypes.oneOf(['fullscreen', 'modal']),
   onCloseButtonClick: PropTypes.func
}

ResourceView.defaultProps = {
   displayMode: 'fullscreen',
   onCloseButtonClick: undefined
}

export default ResourceView
