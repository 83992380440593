// =========================================================================================@@
// Last Updated Date: Mar 28, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Button, View } from 'oio-react'
import PropTypes from 'prop-types'
import { humanizeLocalPolicyPrincipalsName } from 'config/constants/initiativeApps'
import UserListItemDetailsCard from 'src/sites/kits/People/components/UserListItemDetailsCard'
import { EmptyContentBlock } from 'src/sites/kits/UI'
import { useUserList } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const NestedPeoplePage = ({ paddingLeft }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { appsPeopleCurrentUserCanView } = initiative
   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const policyPrincipals = initiative.appsPeoplePolicies.find(p => p.action === 'view')?.principals

   const { userList, fetchMore, loading } = useUserList({
      userRelationshipFilter: {
         entityType: 'initiative',
         entityId: initiative.id,
         hasRoleParticipantElement: {
            roleType: 'primary',
            status: 'active'
         }
      },
      withRelationship: true,
      withRelationshipEntityType: 'initiative',
      withRelationshipEntityId: initiative.id
   }, {
      skip: !appsPeopleCurrentUserCanView
   })

   const handleLoadMore = () => {
      fetchMore({
         variables: {
            start: userList.listInfo.nextCursor
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
               return prevResult
            }

            return {
               ...prevResult,
               userList: {
                  ...prevResult.userList,
                  items: [
                     ...prevResult.userList.items,
                     ...fetchMoreResult.userList.items
                  ],
                  listInfo: fetchMoreResult.userList.listInfo
               }
            }
         }
      })
   }

   return (
      <>
         {appsPeopleCurrentUserCanView && userList.items.length === 0 && (
            <EmptyContentBlock
               message={`No one in this ${initiative.type.nameSingular} to show yet`}
            />
         )}
         {appsPeopleCurrentUserCanView && userList.items.length > 0 && (
            <View
               paddingLeft={paddingLeft}
               margin="0px 0px 20px 0px">
               <div
                  style={{
                     display: 'grid',
                     width: '100%',
                     gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
                     gridGap: 0
                  }}>
                  {userList.items.map(user => (
                     <UserListItemDetailsCard
                        key={user.id}
                        id={user.id}
                        avatarMedia={user.avatarMedia}
                        badges={user.badges}
                        fullName={user.fullName}
                        organization={user.organization}
                        position={user.position}
                     />
                  ))}
               </div>
               {userList.listInfo.hasNext && (
                  <View
                     float="left"
                     width="100%"
                     display="flex"
                     justifyContent="center"
                     padding="12px 0px">
                     <Button
                        color="#000"
                        textColor="#fff"
                        size="xs"
                        onClick={handleLoadMore}
                        name="Load More"
                        mode={loading ? 'loading' : 'normal'}
                        rounded
                     />
                  </View>
               )}
            </View>
         )}
         <EmptyContentBlock
            message={`${humanizeLocalPolicyPrincipalsName(initiative, policyPrincipals)} can see the ${primaryRole.name} of this ${initiative.type.nameSingular}`}
         />
      </>
   )
}

NestedPeoplePage.propTypes = {
   paddingLeft: PropTypes.string
}

NestedPeoplePage.defaultProps = {
   paddingLeft: undefined
}

export default NestedPeoplePage
