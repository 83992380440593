// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useOrganization } from 'src/core/graphql/hooks'
import EventListBlock from 'src/sites/kits/Events/components/ListBlock'
import ObjectCreateControl from 'src/sites/kits/Object/components/CreateControl'
import { BackButton, Title, Titlebar } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const NestedGroupEvents = ({ backButtonUrl, paddingLeft, paddingRight }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const eventInitiativeTypes = organization.initiativeTypes
      .filter(t => t.enabled && t.class === 'event')

   return (
      <>
         <Titlebar
            borderStyle="none"
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}>
            <View display="flex">
               {backButtonUrl && <BackButton linkTo={backButtonUrl} />}
               <Title size="md">
                  Events
               </Title>
            </View>
            <ObjectCreateControl
               initiativeTypes={eventInitiativeTypes}
               parentInitiativeId={initiative.id}
            />
         </Titlebar>
         <EventListBlock
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            parentInitiativeId={initiative.id}
         />
      </>
   )
}

NestedGroupEvents.propTypes = {
   backButtonUrl: PropTypes.string,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

NestedGroupEvents.defaultProps = {
   backButtonUrl: undefined,
   paddingLeft: undefined,
   paddingRight: undefined
}

export default NestedGroupEvents
