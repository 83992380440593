// =========================================================================================@@
// Last Updated Date: Mar 31, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const TabMenuButton = ({
   active,
   icon,
   linkExact,
   linkIsExternal,
   linkTo,
   name,
   onClick,
   onFocus,
   onMouseOver,
   paddingHorizontal,
   pinned,
   rightDetail
}) => {
   let ButtonComponent = 'div'
   let buttonProps = {}

   if (linkTo) {
      ButtonComponent = linkIsExternal ? 'a' : NavLink
      buttonProps = linkIsExternal
         ? { href: linkTo, target: '_blank', rel: 'noopener noreferrer' }
         : { to: linkTo, exact: linkExact, activeClassName: 'active' }
   }

   if (!linkIsExternal && typeof active === 'boolean') {
      buttonProps.isActive = () => active
   }

   return (
      <ButtonComponent
         onClick={onClick}
         onFocus={onFocus}
         onMouseOver={onMouseOver}
         className={`ui-tabmenubutton ${active ? 'active' : ''}`}
         {...buttonProps}>
         <View
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingHorizontal={paddingHorizontal}
            width="100%"
            height="var(--baseComponentHeight-md)">
            <View
               display="flex"
               justifyContent="flex-start"
               alignItems="center">
               {icon && (
                  <View
                     display="flex"
                     alignItems="center"
                     flex="0 0 auto"
                     paddingRight="15px">
                     {icon}
                  </View>
               )}
               <div
                  style={{
                     color: 'inherit',
                     width: '100%',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                  }}>
                  {name}
                  {pinned && '(Pinned)'}
               </div>
            </View>
            {rightDetail}
         </View>
      </ButtonComponent>
   )
}

TabMenuButton.propTypes = {
   active: PropTypes.bool,
   icon: PropTypes.node,
   linkExact: PropTypes.bool,
   linkIsExternal: PropTypes.bool,
   linkTo: PropTypes.string,
   name: PropTypes.string.isRequired,
   onClick: PropTypes.func,
   paddingHorizontal: PropTypes.string,
   pinned: PropTypes.bool,
   rightDetail: PropTypes.node
}

TabMenuButton.defaultProps = {
   active: undefined,
   icon: undefined,
   linkExact: false,
   linkIsExternal: false,
   linkTo: undefined,
   onClick: undefined,
   paddingHorizontal: '8px',
   pinned: false,
   rightDetail: undefined
}

export default TabMenuButton
