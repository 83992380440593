// =========================================================================================@@
// Last Updated Date: Mar 31, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { messagesUrl } from 'config/constants/urls'
import { useMyDirectThreadList, useUser } from 'src/core/graphql/hooks'
import ThreadChatView from 'src/sites/kits/Messages/components/ThreadChatView'
import UserThreadMoreControl from 'src/sites/kits/Messages/components/UserThreadMoreControl'
import { BackButton, Title, Titlebar } from 'src/sites/kits/UI'

const UserThreadChat = ({ marginRight, paddingLeft, paddingRight }) => {
   const { recipientUserId, threadId } = useParams()
   const { user, networkStatus: userNetworkStatus } = useUser({ id: recipientUserId })
   const { refetch: refetchMyDirectThreadList } = useMyDirectThreadList()
   const userInitialLoading = userNetworkStatus <= 2

   if (!user || userInitialLoading) {
      return null
   }

   return (
      <View
         display="flex"
         flexFlow="column"
         position="relative"
         width="100%"
         height="100%">
         <View flex="0 0 auto" paddingRight={marginRight}>
            <Titlebar paddingLeft={paddingLeft} paddingRight={paddingRight}>
               <View display="flex">
                  <BackButton linkTo={messagesUrl} />
                  <div>
                     <Title size="md">
                        {user.fullName}
                     </Title>
                  </div>
               </View>
               <UserThreadMoreControl threadId={threadId} userId={user.id} />
            </Titlebar>
         </View>
         <ThreadChatView
            currentUserCanCreateMessage
            marginRight={marginRight}
            onMessagesLoaded={refetchMyDirectThreadList}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
         />
      </View>
   )
}

UserThreadChat.propTypes = {
   marginRight: PropTypes.string,
   paddingLeft: PropTypes.string,
   paddingRight: PropTypes.string
}

UserThreadChat.defaultProps = {
   marginRight: undefined,
   paddingLeft: undefined,
   paddingRight: undefined
}

export default UserThreadChat
