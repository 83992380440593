// =========================================================================================@@
// Last Updated Date: Sep 28, 2022
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { Button, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import AnnouncementIcon from 'src/sites/custom/gns3/icons/announcement'

// const backgroundColor = 'rgba(40, 255, 79, 0.2)'
// const foregroundColor = '#2db568'

const backgroundColor = 'rgba(248, 223, 59, 0.2)'
const foregroundColor = '#ec9100'
const buttonColor = 'orange'

const HorizontalAdBanner = ({ borderRadius, buttonName, message, url }) => (
   <a href={url} target="_blank" rel="noopener noreferrer">
      <View
         display="flex"
         alignItems="center"
         backgroundColor={backgroundColor}
         padding="8px 8px 8px 16px"
         borderRadius={borderRadius}>
         <AnnouncementIcon width="16px" height="16px" color={foregroundColor} />
         <View flex="1 1 auto" padding="0px 12px">
            <Text size="2" color={foregroundColor} weight="medium">
               {message}
            </Text>
         </View>
         <View flex="0 0 auto">
            <Button
               name={buttonName}
               color={buttonColor}
            />
         </View>
      </View>
   </a>
)

HorizontalAdBanner.propTypes = {
   borderRadius: PropTypes.string,
   buttonName: PropTypes.string.isRequired,
   message: PropTypes.string.isRequired,
   url: PropTypes.string.isRequired
}

HorizontalAdBanner.defaultProps = {
   borderRadius: '3px'
}

export default HorizontalAdBanner
