import React, { useContext } from 'react'
import { css } from 'emotion'
import { Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import { ArrowDownIcon, DraftOutline12Icon, LockGlyph12Icon } from 'assets/icons'
import initiativePrivacyConstants from 'config/constants/initiativePrivacy'
import { initiativeSettingsPrivacyPermissionsUrl } from 'config/constants/urls'
import Popover from 'src/sites/kits/Utils/Popover'
import { useOrganization } from 'src/core/graphql/hooks'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

const ObjectTypeLabel = ({
   displayMode,
   editUrl,
   textSize
}) => {
   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const isSettingsDisplayMode = displayMode === 'settings'
   const isAdminOnly = initiative.privacy === 'admin'
   const isDraft = initiative.privacy === 'draft'
   const isInherit = initiative.privacy === 'inherit'
   const isPrivate = initiative.privacy === 'private'
   const isClosedDiscussion = initiative.discussionStatus?.closed
   const isAnsweredQuestion = isClosedDiscussion && initiative.discussionFormat === 'qa'
   let labelTextColor = '#aaa'

   const currentPrivacyConstants = initiativePrivacyConstants
      .find(p => p.value === initiative.privacy)

   if (initiative.eventIsVirtual) {
      labelTextColor = '#eb8044'
   }

   if (displayMode === 'settings' || isAdminOnly || isDraft) {
      labelTextColor = currentPrivacyConstants.textColor
   }

   if (isClosedDiscussion) {
      labelTextColor = 'rgba(72, 193, 146, 1)'
   }

   return (
      <div
         style={{
            display: 'inline-flex',
            alignItems: 'center',
            margin: 0,
            padding: 0
         }}>
         <Popover.Provider>
            <Popover.Anchor>
               <View
                  position="relative"
                  left="-5px"
                  display="inline-flex"
                  alignItems="center"
                  paddingHorizontal={isSettingsDisplayMode ? '7px' : '5px'}
                  marginLeft={isSettingsDisplayMode && '10px'}
                  borderRadius="4px"
                  backgroundColor={isSettingsDisplayMode && currentPrivacyConstants.backgroundColor}
                  className={css`
                     user-select: none;

                     &:hover {
                        background-color: ${tinycolor(labelTextColor).setAlpha(0.1).toRgbString()};
                     }
                  `}>
                  {!isInherit && (
                     <View
                        flex="0 0 auto"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="50%"
                        marginRight="5px">
                        {(isPrivate) && (
                           <LockGlyph12Icon
                              width="10px"
                              height="10px"
                              color={labelTextColor}
                           />
                        )}
                        {(isAdminOnly || isDraft) && (
                           <DraftOutline12Icon
                              width="10px"
                              height="10px"
                              color={labelTextColor}
                           />
                        )}
                     </View>
                  )}
                  <View flex="1 1 auto">
                     <Text size={textSize} lineHeight="140%" color={labelTextColor}>
                        <View
                           position="relative"
                           display="inline-flex"
                           alignItems="center"
                           borderRadius="3px"
                           marginRight="5px">
                           {isAnsweredQuestion && 'Answered '}
                           {isClosedDiscussion && !isAnsweredQuestion && 'Closed '}
                           {isAdminOnly && 'Admin Draft '}
                           {isDraft && 'Draft '}
                           {isPrivate && 'Private '}
                           {initiative.eventIsVirtual && 'Online '}
                           {initiative.type.nameSingular}
                        </View>
                     </Text>
                  </View>
                  <View position="relative" top="-1px">
                     <ArrowDownIcon
                        width="8px"
                        height="8px"
                        color={labelTextColor}
                        strokeWidth="3px"
                     />
                  </View>
               </View>
            </Popover.Anchor>
            <Popover.View
               anchorOriginHorizontal="left"
               anchorOriginVertical="top"
               backgroundColor="#fff"
               borderRadius="5px"
               margin="0px -10px"
               width="250px">
               <View padding="15px">
                  <Text size="1.5" color="#888">
                     {currentPrivacyConstants.description(initiative, organization)}
                  </Text>
                  {initiative.currentUserCanEdit && (
                     <View
                        marginTop="10px"
                        paddingTop="10px"
                        borderTop="1px solid #eee">
                        <Link to={editUrl || initiativeSettingsPrivacyPermissionsUrl(initiative)}>
                           <Text size="1.5" lineHeight="150%" color="#aaa">
                              <u>
                                 {isSettingsDisplayMode
                                    ? `Edit ${initiative.type.nameSingular} Privacy`
                                    : `Edit ${initiative.type.nameSingular}`
                                 }
                              </u>
                           </Text>
                        </Link>
                     </View>
                  )}
               </View>
            </Popover.View>
         </Popover.Provider>
         {initiative.pinned && (
            <View
               display="inline-flex"
               alignItems="center"
               border="1px solid var(--metaTextColor)"
               opacity="0.4"
               padding="0px 6px"
               borderRadius="16px"
               marginLeft="4px">
               <div className="ui-meta-text">
                  Pinned
               </div>
            </View>
         )}
         {initiative.archived && (
            <View
               display="inline-flex"
               alignItems="center"
               border={`1px solid ${tinycolor(labelTextColor).setAlpha(0.5).toRgbString()}`}
               padding="0px 6px"
               borderRadius="16px"
               marginLeft="4px">
               <div className="ui-meta-text" style={{ color: labelTextColor }}>
                  Archived
               </div>
            </View>
         )}
      </div>
   )
}

ObjectTypeLabel.propTypes = {
   displayMode: PropTypes.oneOf(['default', 'settings']),
   editUrl: PropTypes.string,
   textSize: PropTypes.string
}

ObjectTypeLabel.defaultProps = {
   displayMode: 'default',
   editUrl: undefined,
   textSize: '3'
}

export default ObjectTypeLabel
