// =========================================================================================@@
// Last Updated Date: Apr 13, 2023
// Last Updated By: Steven
// Status Level: 3
// ===========================================================================================

import React from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { adminUrl } from 'config/constants/urls'
import Account from 'src/sites/kits/Account'
import AccountInvitation from 'src/sites/kits/Account/apps/Invitation'
import AdminWorkspace from 'src/sites/kits/AdminWorkspace'
import Bookmarks from 'src/sites/kits/Bookmarks'
import Home from 'src/sites/kits/Home'
import Latest from 'src/sites/kits/Latest'
import Object from 'src/sites/kits/Object'
import ObjectRedirect from 'src/sites/kits/Object/apps/Redirect'
import Messages from 'src/sites/kits/Messages'
import PageCreate from 'src/sites/kits/Object/apps/PageCreate'
import People from 'src/sites/kits/People'
import Profile from 'src/sites/kits/Profile'
import Search from 'src/sites/kits/Search'
import Start from 'src/sites/kits/Start'
import ErrorBoundary from 'src/sites/kits/Utils/ErrorBoundary'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import NotFound from 'src/sites/kits/Utils/NotFound'
import { useGoogleAnalytics } from 'src/sites/kits/Utils'
import { useMe, useOrganization } from 'src/core/graphql/hooks'

// Note to the curious:
// We automagically statically swap DevTools with NotFound in production, so that
// it doesn't bloat the build bundle. See production webpack config for details.
import DevTools from 'src/sites/kits/DevTools'

// Global components
import CookieBanner from '~/components/CookieBanner'
import GlobalLayout from '~/components/Layout'

// Lazy Loaded Components
const Library = React.lazy(() => import('src/sites/kits/Library'))
const OpsCentre = React.lazy(() => import('src/sites/kits/Ops'))

// =======================================================
// Secret Community Utils
// =======================================================

// TODO: Eventually deprecate paths without /-/
const allowedPathsInSecretCommunity = ['/account', '/invitation', '/-/invitation', '/login', '/-/dev']
const pathIsAllowedInSecretCommunity = pathname => (
   allowedPathsInSecretCommunity.some(allowedPath => pathname.startsWith(allowedPath))
)

// =======================================================
// Component
// =======================================================

const GlobalApp = () => {
   const history = useHistory()
   const location = useLocation()
   const { organization, loading: organizationIsLoading } = useOrganization()
   const { me, isLoggedIn, loading: userIsLoading } = useMe()
   useGoogleAnalytics()

   if (organizationIsLoading) {
      return null
   }

   if (!organization) {
      // TODO: Until Core & Sites have been more converged, we throw an error since this should not
      // happen yet as there is no centralized dynamic domain for Gather Sites
      throw new Error('No organization context has been set')
   }

   // If the community is a secret one, then restrict the paths to which the current
   // user is allowed to accesss if they are not logged in.
   if (organization.privacy === 'secret' && !pathIsAllowedInSecretCommunity(location.pathname)) {
      if (userIsLoading) {
         return null
      }

      if (!isLoggedIn) {
         return (
            <Redirect to="/account/login" />
         )
      }
   }

   const defaultFaviconUrl = '/assets/images/defaultFavicon.png'
   const logoFaviconUrl = organization?.logoFavicon?.file.thumbnailUrlW240 || defaultFaviconUrl

   return (
      <>
         <Helmet
            defaultTitle={organization.name}
            link={[{
               rel: 'icon',
               type: 'image/png',
               href: logoFaviconUrl
            }]}
         />
         <GlobalLayout>
            <Switch>
               <ModalRoute path={adminUrl}>
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <>
                        <Home />
                        <AdminWorkspace
                           modalOnCloseComplete={onCloseComplete}
                           modalOnCloseTrigger={onCloseTrigger}
                           modalOpen={open}
                        />
                     </>
                  )}
               </ModalRoute>

               <ModalRoute path="/-/library">
                  {({ open, onCloseComplete, onCloseTrigger }) => (
                     <>
                        <Home />
                        <React.Suspense fallback={null}>
                           <Library
                              modalOnCloseComplete={onCloseComplete}
                              modalOnCloseTrigger={onCloseTrigger}
                              modalOpen={open}
                           />
                        </React.Suspense>
                     </>
                  )}
               </ModalRoute>

               <Route path="/" exact component={Home} />
               <Route path="/-/home" component={Home} />
               <Route path="/-/bookmarks" component={Bookmarks} />
               <Route path="/-/latest" component={Latest} />

               {/* TODO: Deprecate login redirect */}
               <Redirect from="/login" to="/account/login" />
               <Route path="/account" component={Account} />

               <Redirect from="/invitation" to="/-/invitation" />
               <Route path="/-/invitation" component={AccountInvitation} />

               {/* TODO: Deprecate messages redirect in 5.24 */}
               <Redirect from="/messages" to="/-/messages" />
               <Route path="/-/messages" component={Messages} />

               <Route path="/initiatives/:initiativeSlug" component={ObjectRedirect} />
               <Route path="/-/initiatives/:initiativeSlug" component={ObjectRedirect} />

               {/* Page Route */}
               <Route
                  path="/-/pages/create"
                  render={() => (
                     <PageCreate
                        onCancelButtonClick={() => history.push('/')}
                        onCreate={page => history.push(page.gatherUrl)}
                     />
                  )}
               />

               {/* Profile Routes */}
               {organization.memberListEnabled && <Route path="/-/people" component={People} />}
               <Redirect from="/-/profile" exact to="/-/profile/me" />
               {isLoggedIn && <Redirect from={`/-/profile/${me.id}`} exact to="/-/profile/me" />}
               <Route path="/-/profile/:userId" component={Profile} />

               <Route path="/-/search/:query?" component={Search} />
               <Route path="/-/start" component={Start} />

               {/* Operational Tools Limited to Mother Users */}
               <Route path="/-/ops" render={() => (
                  <React.Suspense fallback={null}>
                     <OpsCentre />
                  </React.Suspense>
               )} />

               {/* We automatically statically replace DevTools with NotFound
                   in production builds */}
               <Route path="/-/dev" component={DevTools} />

               <Route path="/:initiativeSlug" render={routeProps => (
                  <ErrorBoundary key={routeProps.match.params.initiativeSlug} height="100vh">
                     <Object />
                  </ErrorBoundary>
               )} />

               <Route component={NotFound} />
            </Switch>
         </GlobalLayout>
         {/* TODO: This should be made generic
         See PR #360 */}
         {organization.slug === 'gns3' && <CookieBanner />}
      </>
   )
}

export default GlobalApp
