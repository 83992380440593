// =========================================================================================@@
// Last Updated Date: Mar 28, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useContext } from 'react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { useLocation, useRouteMatch } from 'react-router-dom'
import NestedPageOptionsControl from 'src/sites/kits/Object/components/NestedPageOptionsControl'
import { TabMenuButton } from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import NestedCreateControl from './NestedCreateControl'

const determineSelectedMenuLink = (links, currentLocationPathname) => {
   let indexOfLinkWithLongestMatch = -1

   links.forEach((link, i) => {
      if (currentLocationPathname.startsWith(link.linkTo)) {
         if (indexOfLinkWithLongestMatch > -1) {
            if (link.linkTo.length > links[indexOfLinkWithLongestMatch].linkTo.length) {
               indexOfLinkWithLongestMatch = i
            }
         } else {
            indexOfLinkWithLongestMatch = i
         }
      }
   })

   return indexOfLinkWithLongestMatch
}

const ObjectViewNav = ({ alignItems, borderStyle, height }) => {
   const location = useLocation()
   const match = useRouteMatch()

   const { initiative } = useContext(InitiativeHierarchyContext)
   const primaryRole = initiative.roles.find(r => r.type === 'primary')
   const userCanAddPages = ['event', 'group', 'resource', 'session'].includes(initiative.class) &&
      initiative.currentUserCanEdit

   // Show all pages (including unpublished) to users that can edit
   const customPages = initiative.currentUserCanEdit
      ? initiative.customPages
      : initiative.customPages.filter(p => p.published)

   const navTabs = [{
      name: initiative.class === 'session'
         ? 'Overview'
         : `${initiative.type.nameSingular} Home`,
      active: false,
      linkTo: match.url,
      exact: true,
      editUrl: `${match.url}/-/settings`,
      pageType: 'home'
   }]

   if (initiative.class === 'event' && initiative.appsScheduleEnabled) {
      const { appsSchedulePublished, currentUserCanEdit } = initiative
      if (appsSchedulePublished || currentUserCanEdit) {
         navTabs.push({
            name: 'Schedule',
            active: false,
            linkTo: `${match.url}/-/schedule`,
            pageType: 'schedule'
         })
      }
   }

   customPages.forEach((page) => {
      navTabs.push({
         name: page.title,
         active: false,
         linkTo: `${match.url}/${page.slug}`,
         editUrl: `${match.url}/${page.slug}/-/edit`,
         pageId: page.id,
         pagePublished: page.published,
         pageType: 'custom'
      })
   })

   if (['event', 'group'].includes(initiative.class) && primaryRole && initiative.appsPeopleEnabled) {
      navTabs.push({
         name: primaryRole.name,
         active: false,
         linkTo: `${match.url}/-/people`,
         pageType: 'people'
      })
   }

   if (initiative.class === 'group' && initiative.appsEventsEnabled) {
      navTabs.push({
         name: 'Events',
         active: false,
         linkTo: `${match.url}/-/events`,
         pageType: 'events'
      })
   }

   const selectedLinkIndex = determineSelectedMenuLink(navTabs, location.pathname)
   if (selectedLinkIndex > -1) {
      navTabs[selectedLinkIndex].active = true
   }

   return (
      <View
         id="gather-object-nav"
         display="flex"
         justifyContent="space-between"
         alignItems={alignItems}
         width="100%"
         height={height}
         borderBottom={borderStyle}>
         <ScrollArea.Root className="ScrollAreaRoot">
            <ScrollArea.Viewport className="ScrollAreaViewport">
               <View
                  display="flex"
                  alignItems={alignItems}
                  width={initiative.currentUserCanEdit ? 'calc(100% - 40px)' : '100%'}
                  height={height}
                  style={{ gap: '4px' }}>
                  {navTabs.map(button => (
                     <TabMenuButton
                        key={button.linkTo}
                        name={button.name}
                        linkTo={button.linkTo}
                        linkExact={button.exact}
                        rightDetail={button.active && (
                           <NestedPageOptionsControl
                              editUrl={button.editUrl}
                              pageId={button.pageId}
                              pageName={button.name}
                              pagePublished={button.pagePublished}
                              pageType={button.pageType}
                           />
                        )}
                     />
                  ))}
               </View>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
               <ScrollArea.Thumb className="ScrollAreaThumb" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner className="ScrollAreaCorner" />
         </ScrollArea.Root>
         {userCanAddPages && (
            <NestedCreateControl />
         )}
      </View>
   )
}

ObjectViewNav.propTypes = {
   alignItems: PropTypes.string,
   borderStyle: PropTypes.string,
   height: PropTypes.string
}

ObjectViewNav.defaultProps = {
   alignItems: 'center',
   borderStyle: '1px solid var(--primaryLineColor)',
   height: '64px'
}

export default ObjectViewNav
