// =========================================================================================@@
// Last Updated Date: Jan 4, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React from 'react'
import { View } from 'oio-react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import UserBadgeSash from 'src/sites/kits/People/components/UserBadgeSash'
import { Avatar, Title } from 'src/sites/kits/UI'

const UserListItemDetailsCard = ({
   avatarMedia,
   avatarSize,
   badges,
   fullName,
   id,
   organization,
   position
}) => {
   return (
      <Link to={`/-/profile/${id}`}>
         <View
            display="flex"
            alignItems="center"
            width="100%"
            height="100%"
            borderBottom="1px solid var(--primaryLineColor)"
            paddingVertical="16px"
            style={{ gap: '16px' }}>
            <View flex="0 0 auto" marginTop="6px">
               <Avatar
                  fullName={fullName}
                  size={avatarSize}
                  src={avatarMedia?.file.thumbnailUrlW48}
               />
            </View>
            <View
               flex="1 1 auto"
               paddingRight="24px"
               style={{ width: '100%', overflow: 'hidden' }}>
               <View maxWidth="100%">
                  <Title size="xs">
                     {fullName}
                  </Title>
                  <View className="ui-meta-text" paddingTop="0px">
                     {(organization || position) && (
                        <>
                           <span>{organization}</span>
                           {organization && position && (
                              <span>, </span>
                           )}
                           <span>{position}</span>
                           &nbsp;&#183;&nbsp;
                        </>
                     )}
                     Member
                  </View>
                  <UserBadgeSash badges={badges} />
               </View>
            </View>
         </View>
      </Link>
   )
}

UserListItemDetailsCard.propTypes = {
   avatarMedia: PropTypes.object,
   avatarSize: PropTypes.string,
   badges: PropTypes.array,
   fullName: PropTypes.string.isRequired,
   id: PropTypes.string.isRequired,
   organization: PropTypes.string,
   position: PropTypes.string
}

UserListItemDetailsCard.defaultProps = {
   avatarMedia: undefined,
   avatarSize: '30px',
   badges: [],
   organization: undefined,
   position: undefined
}

export default UserListItemDetailsCard
